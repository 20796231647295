import * as React from "react";
import styled from "styled-components";
import range from "lodash-es/range";
import { FontStatus } from "state";
import { scrollable } from "ui/theme";
;
import { ListItem, List } from "ui/list";
const itemHeight = 88;
const ItemName = styled.div `
font-size: 16px;
line-height: 24px;
font-weight: 600;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
line-height: 16px;
`;
const ItemExample = styled.div `
font-size: 24px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
line-height: 32px;
`;
const FontListItem = styled(ListItem) `
flex-direction: column;
height: ${itemHeight}px;
box-sizing: border-box;
position: absolute;
width: 100%;
`;
const InvisibleItem = styled.div `height: ${itemHeight}px;`;
export function Item({ onClick, onPreloadRequest, item: { family, status }, top }) {
    const handleClick = React.useCallback(() => onClick(family), [family]);
    React.useEffect(() => {
        if (status === undefined || status === FontStatus.Initial || status === FontStatus.Failed) {
            const timeout = setTimeout(() => {
                onPreloadRequest(family);
            }, 150);
            return () => clearTimeout(timeout);
        }
    }, [onPreloadRequest, family, status]);
    const sampleStyle = React.useMemo(() => {
        if (status === FontStatus.Ready) {
            return { fontFamily: family };
        }
        else {
            return undefined;
        }
    }, [status, family]);
    const itemStyle = React.useMemo(() => ({ top }), [top]);
    return React.createElement(FontListItem, { style: itemStyle, onClick: handleClick, loading: status === FontStatus.Loading },
        React.createElement(ItemName, { style: sampleStyle }, family),
        React.createElement(ItemExample, { style: sampleStyle }, "The quick brown fox jumps over the lazy dog"));
}
const ListWrapper = scrollable(styled.div `
box-sizing: border-box;
`);
const ignoreLoadingStatuses = [FontStatus.Loading, FontStatus.Ready];
export function FontList({ items, height, onClick, onPreloadRequest }) {
    const [scroll, setScroll] = React.useState(0);
    const scrollHandler = React.useCallback((e) => {
        setScroll(e.target.scrollTop);
    }, [setScroll]);
    const i1 = Math.min(items.length, Math.floor(scroll / itemHeight)), i2 = Math.min(items.length, Math.ceil((scroll + height) / itemHeight));
    return React.createElement(List, { style: { height, maxHeight: "80vh" }, onScroll: scrollHandler },
        React.createElement("div", { style: { position: "relative", height: itemHeight * items.length } }, range(i1, i2).map(i => {
            const item = items[i];
            return React.createElement(Item, { key: item.family, top: i * itemHeight, item: item, onClick: onClick, onPreloadRequest: onPreloadRequest });
        })));
}
