import { connect } from "react-redux";
import { SoundSelect } from "ui/sound-select";
import { selectCurrentTrack, selectTrackList, selectMusicVolume, settings } from "state";
export const MusicSelectorConnected = connect((state) => ({
    value: selectCurrentTrack(state),
    volume: selectMusicVolume(state),
    sounds: selectTrackList(state)
}), dispatch => ({
    onSelectSound: (track) => {
        dispatch(settings.setMusic(track));
    },
    onVolumeChange: (volume) => {
        dispatch(settings.setMusicVolume(volume));
    }
}))(SoundSelect);
