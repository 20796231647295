var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { backgroundsReducer, settingsReducer, createSettingsMiddleware, backgroundsMiddleware, editorMiddleware, editorReducer, menuReducer, menuMiddleware, musicReducer, musicMiddleware, saveSettingsMiddleware, typingSoundsReducer, typingSoundsMiddleware, dialogReducer, dialogMiddleware, entriesReducer, entriesMiddleware, userMiddleware, userReducer, user, entries, editor, backgrounds, music, typingSounds, settings, saveDocumentMiddleware, visibility, visibilityReducer, visibilityMiddleware, musicVolumeMiddleware, currentTrackMiddleware, loadFontMiddleware, loadTypingSoundsMiddleware, loadBackgroundMiddleware, openDocumentMiddleware, typingReducer, createTypingMiddleware, typing, createScreenMiddleware, screenReducer, screen, fullscreenReducer, createFullscreenMiddleware, fullscreen, createTextCounterMiddleware, textCountersReducer, updateCountersMiddleware, } from "state";
import { fontsReducer, fontsMiddleware, fonts } from "state/fonts";
import { ZenDatabase } from "api";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
export function createZenStore() {
    return __awaiter(this, void 0, void 0, function* () {
        const app = firebase.initializeApp(process.env.FIREBASE_CONFIG), database = new ZenDatabase(app), store = createStore(combineReducers({
            backgrounds: backgroundsReducer,
            settings: settingsReducer,
            editor: editorReducer,
            menu: menuReducer,
            fonts: fontsReducer,
            music: musicReducer,
            typingSounds: typingSoundsReducer,
            dialog: dialogReducer,
            entries: entriesReducer,
            user: userReducer,
            isVisible: visibilityReducer,
            typing: typingReducer,
            screen: screenReducer,
            fullscreen: fullscreenReducer,
            textCounters: textCountersReducer,
        }), composeWithDevTools(applyMiddleware(backgroundsMiddleware(), createSettingsMiddleware(database), visibilityMiddleware(), createTypingMiddleware(), editorMiddleware(database), menuMiddleware(), fontsMiddleware(), musicMiddleware(), typingSoundsMiddleware(), dialogMiddleware(), entriesMiddleware(database), userMiddleware(database), createScreenMiddleware(), createFullscreenMiddleware(), createTextCounterMiddleware(), saveSettingsMiddleware, saveDocumentMiddleware, musicVolumeMiddleware, currentTrackMiddleware, loadFontMiddleware, loadTypingSoundsMiddleware, loadBackgroundMiddleware, openDocumentMiddleware, updateCountersMiddleware)));
        store.dispatch(user.watch());
        store.dispatch(editor.watch());
        store.dispatch(entries.watch());
        store.dispatch(backgrounds.loadList());
        store.dispatch(fonts.loadList());
        store.dispatch(music.loadList());
        store.dispatch(typingSounds.loadList());
        store.dispatch(settings.watch());
        store.dispatch(visibility.watch());
        store.dispatch(typing.watch());
        store.dispatch(screen.watch());
        store.dispatch(fullscreen.watch());
        yield database.start();
        store.dispatch(settings.load());
        return store;
    });
}
