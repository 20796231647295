import { Schema } from "prosemirror-model";
import { chainCommands, exitCode } from "prosemirror-commands";
export var BlockAlign;
(function (BlockAlign) {
    BlockAlign["Left"] = "left";
    BlockAlign["Right"] = "right";
    BlockAlign["Center"] = "center";
    BlockAlign["Justify"] = "justify";
})(BlockAlign || (BlockAlign = {}));
export var BlockType;
(function (BlockType) {
    BlockType["Chapter"] = "c";
    BlockType["Paragraph"] = "p";
})(BlockType || (BlockType = {}));
(function (BlockType) {
    function getTagName(type) {
        switch (type) {
            case BlockType.Paragraph: return "p";
            case BlockType.Chapter: return "h2";
            default: return "p";
        }
    }
    BlockType.getTagName = getTagName;
    const labels = {
        [BlockType.Paragraph]: "Normal text",
        [BlockType.Chapter]: "Chapter"
    };
    function getLabel(type) {
        return labels[type];
    }
    BlockType.getLabel = getLabel;
})(BlockType || (BlockType = {}));
export const schema = new Schema({
    nodes: {
        doc: {
            content: "block+",
            parseDOM: [{ tag: "div" }],
            toDOM() { return ["div", 0]; }
        },
        paragraph: {
            content: "inline*",
            group: "block",
            attrs: {
                type: { default: BlockType.Paragraph },
                align: { default: BlockAlign.Justify }
            },
            parseDOM: [
                ["h1", BlockType.Chapter],
                ["h2", BlockType.Chapter],
                ["h3", BlockType.Chapter],
                ["p", BlockType.Paragraph]
            ].map(([tag, type]) => {
                return {
                    tag,
                    getAttrs(n) {
                        if (typeof n === "string") {
                            return { type };
                        }
                        else {
                            const node = n;
                            if (["center", "justify", "left", "right"].indexOf(node.style.textAlign) !== -1) {
                                return { type, align: node.style.textAlign };
                            }
                            else {
                                return { type };
                            }
                        }
                    }
                };
            }),
            toDOM(node) {
                return [BlockType.getTagName(node.attrs["type"]), { style: `text-align: ${node.attrs["align"]};` }, 0];
            }
        },
        text: {
            group: "inline",
            inline: true
        },
        hard_break: {
            group: "inline",
            selectable: false,
            parseDOM: [{ tag: "br" }],
            toDOM() { return ["br"]; },
            inline: true
        }
    },
    marks: {
        i: {
            parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
            toDOM() { return ["i"]; }
        },
        u: {
            parseDOM: [{ tag: "u" }, { style: "text-decoration=underline" }],
            toDOM() { return ["u"]; }
        },
        b: {
            parseDOM: [{ tag: "b" }, { tag: "strong" },
                // This works around a Google Docs misbehavior where
                // pasted content will be inexplicably wrapped in `<b>`
                // tags with a font-weight normal.
                { tag: "b", getAttrs: (node) => node.style.fontWeight != "normal" && null },
                { style: "font-weight", getAttrs: (value) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null }],
            toDOM() { return ["b"]; }
        },
    }
});
export const alignNodeCommand = (align) => (state, dispatch) => {
    const { from, to } = state.selection;
    let transaction = state.tr;
    state.doc.nodesBetween(from, to, (node, pos) => {
        if ([schema.nodes.paragraph, schema.nodes.subheader].indexOf(node.type) !== -1) {
            transaction = transaction.setNodeMarkup(pos, node.type, Object.assign(Object.assign({}, (node.attrs || {})), { align }), node.marks);
        }
        return false;
    });
    dispatch && dispatch(transaction);
    return true;
};
export const setNodeTypeCommand = (type) => (state, dispatch) => {
    const { from, to } = state.selection;
    let transaction = state.tr;
    state.doc.nodesBetween(from, to, (node, pos) => {
        if ([schema.nodes.paragraph, schema.nodes.subheader].indexOf(node.type) !== -1) {
            transaction = transaction.setNodeMarkup(pos, node.type, Object.assign(Object.assign({}, (node.attrs || {})), { type }), node.marks);
        }
        return false;
    });
    dispatch && dispatch(transaction);
    return true;
};
function getBlockAttr(state, name) {
    const range = state.selection.ranges[0];
    if (range) {
        const p = range.$from;
        for (let i = 0; i <= p.depth; i++) {
            const node = p.node(i);
            if (node.type.name === "paragraph") {
                return node.attrs[name] || null;
            }
        }
    }
    return null;
}
export function getBlockType(state) {
    return getBlockAttr(state, "type") || BlockType.Paragraph;
}
export function getBlockAlign(state) {
    return getBlockAttr(state, "align") || BlockAlign.Justify;
}
export const hardBreak = chainCommands(exitCode, (state, dispatch) => {
    dispatch && dispatch(state.tr.replaceSelectionWith(schema.nodes.hard_break.create()).scrollIntoView());
    return true;
});
export const scrollIntoView = chainCommands(exitCode, (state, dispatch) => {
    dispatch && dispatch(state.tr.scrollIntoView());
    return true;
});
export function alignBlock(align) {
    return (state, dispatch) => {
        const { from, to } = state.selection;
        let transaction = state.tr;
        state.doc.nodesBetween(from, to, (node, pos) => {
            if ([schema.nodes.paragraph, schema.nodes.subheader].indexOf(node.type) !== -1) {
                transaction = transaction.setNodeMarkup(pos, node.type, Object.assign(Object.assign({}, (node.attrs || {})), { align }), node.marks);
            }
            return false;
        });
        dispatch && dispatch(transaction);
        return true;
    };
}
export function setBlockType(type) {
    return (state, dispatch) => {
        const { from, to } = state.selection;
        let transaction = state.tr;
        state.doc.nodesBetween(from, to, (node, pos) => {
            if ([schema.nodes.paragraph, schema.nodes.subheader].indexOf(node.type) !== -1) {
                transaction = transaction.setNodeMarkup(pos, node.type, Object.assign(Object.assign({}, (node.attrs || {})), { type }), node.marks);
            }
            return false;
        });
        dispatch && dispatch(transaction);
        return true;
    };
}
