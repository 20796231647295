import * as React from "react";
import styled from "styled-components";
import { BaseButton, RippleOrigin } from "ui/button";
import { ThemeColor, scrollable } from "ui/theme";
import { Loading } from "ui/loading";
const ListWrapper = scrollable(styled.div ``);
export function List(props) {
    return React.createElement(ListWrapper, Object.assign({}, props));
}
const ListItemNode = styled(BaseButton) `
position: relative;
overflow: hidden;
display: flex;
align-items: stretch;
justify-content: space-between;
padding: 12px 16px;
transition: background-color 0.3s;
border-radius: 2px;
${({ hover }) => hover ? `background: ${ThemeColor.Border};` : ""}
`;
const ListItemLoading = styled(Loading) `
position: absolute;
left: 0;
right: 0;
bottom: 0;
`;
export function ListItem({ className, children, loading, onClick, style, active = true }) {
    const [hover, setHover] = React.useState(false);
    return React.createElement(ListItemNode, { hover: active && hover, className: className, rippleOrigin: RippleOrigin.MOUSE, rippleColor: ThemeColor.Border, active: active, onClick: onClick, onHover: setHover, style: style },
        children,
        React.createElement(ListItemLoading, { loading: !!loading }));
}
