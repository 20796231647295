import { schema } from "state/schema";
import { selectEditorTitle } from "state/selectors";
function nodeToString(node) {
    switch (node.type) {
        case schema.nodes.doc:
            const output = [];
            node.forEach(node => output.push(nodeToString(node)));
            return output.join("\n\n");
        case schema.nodes.paragraph:
            let paragraph = "";
            node.forEach(child => {
                if (child.type === schema.nodes.hard_break) {
                    paragraph += "\n";
                }
                else {
                    paragraph += child.textContent;
                }
            });
            if (node.attrs.type === "c") {
                return paragraph.replace(/(^|\n)/g, "$1## ");
            }
            else {
                return paragraph;
            }
        default:
            throw new Error(`Unknown node type: '${node.type.name}'`);
    }
}
export function convertToTxt(state) {
    if (!state.editor) {
        throw new Error("Can not convert emty document");
    }
    const title = selectEditorTitle(state), body = nodeToString(state.editor.content.doc);
    if (title) {
        return new Blob(["# ", title, "\n\n", body], { endings: "native", type: "text/plain" });
    }
    else {
        return new Blob([body], { endings: "native", type: "text/plain" });
    }
}
