import * as React from "react";
import styled, { keyframes } from "styled-components";
export var SlideTransition;
(function (SlideTransition) {
    SlideTransition["None"] = "None";
    SlideTransition["SlideUp"] = "SlideUp";
    SlideTransition["SlideDown"] = "SlideDown";
    SlideTransition["ZoomIn"] = "ZoomIn";
})(SlideTransition || (SlideTransition = {}));
const slideKeyframes = {
    [SlideTransition.None]: keyframes `from { opacity: 0; } to { opacity: 1; }`,
    [SlideTransition.SlideUp]: keyframes `from { transform: none; opacity: 1; } to { transform: translateY(-100px) scale(1.05); opacity: 0; }`,
    [SlideTransition.SlideDown]: keyframes `from { transform: none; opacity: 1; } to { transform: translateY(100px) scale(0.95); opacity: 0; }`,
    [SlideTransition.ZoomIn]: keyframes `from { transform: none; opacity: 1; } to { transform: scale(1.05); opacity: 0; }`,
};
const SlideNode = styled.div `
animation-duration: 300ms;
animation-name: ${({ destination }) => slideKeyframes[destination]};
opacity: ${({ destination }) => destination === SlideTransition.None ? 1 : 0};
${({ outdated }) => outdated
    ? `position: absolute; pointer-events: none; width: 100%; z-index: 1;`
    : `position: relative;`};
left: 0;
top: 0;
`;
function Slide({ id, children, transition: destination, onHide }) {
    const [appeared, setAppeared] = React.useState(false);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setAppeared(true);
        }, 300);
        return () => clearTimeout(timeout);
    }, []);
    React.useEffect(() => {
        if (appeared && destination !== SlideTransition.None) {
            const timeout = setTimeout(() => {
                onHide(id);
            }, 300);
            return () => clearTimeout(timeout);
        }
    }, [appeared, destination, id, onHide]);
    return React.createElement(SlideNode, { destination: appeared ? destination : SlideTransition.None, outdated: destination !== SlideTransition.None }, children);
}
;
export function createLevelSlider(ChildComponent, id, transition) {
    let counter = 0;
    return class extends React.Component {
        constructor() {
            super(...arguments);
            this.state = { items: [] };
            this.removeSlide = (id) => {
                this.setState(state => ({
                    items: state.items.filter(item => item.uniqueId !== id)
                }));
            };
        }
        render() {
            const { items } = this.state;
            return React.createElement(React.Fragment, null, items.map(({ uniqueId, childProps, transition }) => {
                return React.createElement(Slide, { key: uniqueId, id: uniqueId, transition: transition, onHide: this.removeSlide },
                    React.createElement(ChildComponent, Object.assign({}, childProps)));
            }));
        }
        static getDerivedStateFromProps(props, state) {
            if (state.items.length) {
                const begin = state.items.slice(0, state.items.length - 1), last = state.items[state.items.length - 1];
                if (id(last.childProps) !== id(props)) {
                    return {
                        items: [...begin, {
                                childProps: last.childProps,
                                transition: transition(last.childProps, props),
                                uniqueId: last.uniqueId
                            }, {
                                childProps: props,
                                uniqueId: `slide${counter++}`,
                                transition: SlideTransition.None
                            }]
                    };
                }
                else {
                    return {
                        items: [...begin, {
                                childProps: props,
                                transition: SlideTransition.None,
                                uniqueId: last.uniqueId
                            }]
                    };
                }
            }
            else {
                return {
                    items: [{
                            childProps: props,
                            transition: SlideTransition.None,
                            uniqueId: `slide${counter++}`
                        }]
                };
            }
        }
    };
}
