import * as React from "react";
import { useState, useCallback } from "react";
import styled from "styled-components";
import { Ripple } from "ui/ripple";
import { ThemeColor, shadow } from "ui/theme";
import Icon from "@mdi/react";
export var RippleOrigin;
(function (RippleOrigin) {
    RippleOrigin[RippleOrigin["MOUSE"] = 0] = "MOUSE";
    RippleOrigin[RippleOrigin["CENTER"] = 1] = "CENTER";
})(RippleOrigin || (RippleOrigin = {}));
let counter = 0;
const BaseButtonNode = styled.div `
outline: none;
cursor: ${({ active }) => active ? "pointer" : "default"};
user-select: none;
`;
const noop = () => { };
export function BaseButton({ rippleOrigin, rippleColor, children, style, className, onClick, onHover, active = true }) {
    const [ripples, setRipples] = useState([]);
    const buttonId = React.useMemo(() => `btn${(counter++).toFixed()}`, []);
    const clickHandler = useCallback((e) => {
        if (!e.defaultPrevented && active) {
            e.preventDefault();
            const { clientWidth: width, clientHeight: height } = e.currentTarget, bounds = e.currentTarget.getBoundingClientRect(), point = rippleOrigin === RippleOrigin.CENTER ? {
                x: width / 2,
                y: height / 2
            } : {
                x: e.clientX - bounds.left,
                y: e.clientY - bounds.top
            };
            const id = counter++;
            setRipples(ripples => [
                ...ripples,
                Object.assign({ id, size: Math.max(width, height) * 2 }, point)
            ]);
            onClick && onClick();
        }
    }, [rippleOrigin, rippleColor, active]);
    const removeHandler = useCallback((id) => {
        setRipples(ripples => ripples.filter(r => r.id !== id));
    }, [setRipples]);
    const overHandler = React.useCallback((e) => {
        if (onHover) {
            const el = e.target;
            for (let i = el; i; i = i.parentElement) {
                let id = i.getAttribute("data-button-id");
                if (id) {
                    onHover(id === buttonId);
                    return;
                }
            }
            onHover(false);
        }
    }, [onHover]), outHandler = React.useCallback((e) => {
        if (onHover) {
            const el = e.target;
            for (let i = el; i; i = i.parentElement) {
                let id = i.getAttribute("data-button-id");
                if (id) {
                    if (id === buttonId) {
                        onHover(false);
                    }
                    return;
                }
            }
        }
    }, [onHover]);
    return React.createElement(BaseButtonNode, { active: active, "data-button-id": buttonId, tabIndex: 0, className: className, onClick: clickHandler, onMouseOver: overHandler, onMouseOut: outHandler, style: style },
        children,
        ripples.map(({ id, x, y, size }) => {
            return React.createElement(Ripple, { key: id, rippleId: id, x: x, y: y, size: size, color: rippleColor, onRemove: removeHandler });
        }));
}
const FlatButtonNode = styled(BaseButton) `
position: relative;
cursor: pointer;
border-radius: 2px;
padding: 8px 16px;
min-width: 80px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
transition: background-color 0.3s;
overflow: hidden;
user-select: none;
text-transform: uppercase;
font-size: 14px;
font-weight: 500;
will-change: opacity;
:hover {
    background-color: ${ThemeColor.Border};
}`;
export function FlatButton({ rippleColor, children, onClick, style, className, active }) {
    return React.createElement(FlatButtonNode, { rippleColor: rippleColor || ThemeColor.Border, rippleOrigin: RippleOrigin.MOUSE, onClick: onClick, style: style, active: active, className: className }, children);
}
const RaisedButtonNode = styled(BaseButton) `
position: relative;
cursor: pointer;
border-radius: 2px;
padding: 8px 16px;
min-width: 80px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
transition: background-color 0.3s, box-shadow 0.3s;
overflow: hidden;
user-select: none;
text-transform: uppercase;
font-size: 14px;
font-weight: 500;
will-change: opacity, box-shadow;
box-shadow: ${shadow(2)};
:hover {
    background-color: ${ThemeColor.Border};
}
:active {
    box-shadow: ${shadow(8)};
}`;
export function RaisedButton({ rippleColor, children, onClick, style, className }) {
    return React.createElement(RaisedButtonNode, { rippleColor: rippleColor || ThemeColor.Border, rippleOrigin: RippleOrigin.MOUSE, onClick: onClick, style: style, className: className }, children);
}
const IconButtonNode = styled(BaseButton) `
position: relative;
width: 24px;
height: 24px;
padding: 4px;
border-radius: 50%;
transition: background-color 0.3s;
:hover {
    background: ${ThemeColor.Border};
}
`;
IconButtonNode.displayName = "IconButtonNode";
export function IconButton({ icon, rippleColor = ThemeColor.Border, iconColor = ThemeColor.SecondaryText, className, onClick }) {
    return React.createElement(IconButtonNode, { rippleOrigin: RippleOrigin.CENTER, rippleColor: rippleColor, className: className, onClick: onClick },
        React.createElement(Icon, { size: "24px", color: iconColor, path: icon }));
}
