import { createReduxBlock } from "redux-sacala";
import { DialogType } from "./state";
export const { actions: dialog, createMiddleware: dialogMiddleware, reducer: dialogReducer } = createReduxBlock()({
    name: "dialog",
    initial: DialogType.None,
    actions: {
        chooseFont() {
            return DialogType.SelectFont;
        },
        close() {
            return DialogType.None;
        }
    }
});
