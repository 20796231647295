import * as React from "react";
import { connect } from "react-redux";
import Icon from "@mdi/react";
import styled from "styled-components";
import { FlatButton, IconButton } from "ui/button";
import { selectCurrentDocumentId, settings, selectScreenWidth, isMobile } from "state";
import { ThemeColor } from "ui/theme";
import { mdiChevronLeft } from "@mdi/js";
const DesktopSwitchButton = styled(FlatButton) `
opacity: ${({ show }) => show ? "1" : "0"};
pointer-events: ${({ show }) => show ? "auto" : "none"};
transition: opacity 0.3s;
`;
DesktopSwitchButton.displayName = "DesktopSwitchButton";
const MobileSwitchButton = styled(IconButton) `
opacity: ${({ show }) => show ? "1" : "0"};
pointer-events: ${({ show }) => show ? "auto" : "none"};
transition: opacity 0.3s;
`;
MobileSwitchButton.displayName = "MobileSwitchButton";
const SwitchButtonCaption = styled.span `
display: inline-block;
margin-left: 8px;
`;
SwitchButtonCaption.displayName = "SwitchButtonCaption";
function SwitchScreenButton({ show = true, caption, onClick, isMobile }) {
    return isMobile
        ? React.createElement(MobileSwitchButton, { icon: mdiChevronLeft, show: show, onClick: onClick })
        : React.createElement(DesktopSwitchButton, { show: show, onClick: onClick },
            React.createElement(Icon, { color: ThemeColor.SecondaryText, path: mdiChevronLeft, size: "24px" }),
            React.createElement(SwitchButtonCaption, null, caption));
}
export const SwitchToLibraryButton = connect((state) => ({
    caption: "Library",
    isMobile: isMobile(selectScreenWidth(state))
}), dispatch => ({
    onClick: () => {
        dispatch(settings.switchToLibrary());
    }
}))(SwitchScreenButton);
export const SwitchToDocumentButton = connect((state) => ({
    caption: "Back",
    isMobile: isMobile(selectScreenWidth(state)),
    show: !!selectCurrentDocumentId(state),
}), dispatch => ({
    onClick: () => {
        dispatch(settings.switchToDocument());
    }
}))(SwitchScreenButton);
