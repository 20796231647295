import * as React from "react";
import { Flipper, Flipped } from "react-flip-toolkit";
import { FsEntry } from "ui/fs-entry";
import { createLevelSlider, SlideTransition } from "ui/level-slider";
export const FsEntryList = createLevelSlider(({ entries, linkToParent, editEntryId, onEdit, onRename, onDelete, onClick, onMove, onCopy, }) => {
    return React.createElement(Flipper, { flipKey: `${linkToParent && linkToParent.id}-${entries.map(e => e.id).join("-")}}` },
        linkToParent && React.createElement(FsEntry, { isLinkToParent: true, entry: linkToParent, edit: false, onClick: onClick, onDelete: onDelete, onEdit: onEdit, onRename: onRename, onMove: onMove, onCopy: onCopy }),
        entries.map(entry => React.createElement(Flipped, { key: entry.id, flipId: entry.id },
            React.createElement("div", null,
                React.createElement(FsEntry, { entry: entry, isLinkToParent: false, edit: entry.id === editEntryId, onClick: onClick, onDelete: onDelete, onEdit: onEdit, onRename: onRename, onMove: onMove, onCopy: onCopy })))));
}, ({ linkToParent }) => linkToParent ? linkToParent.id : "null", () => SlideTransition.ZoomIn);
