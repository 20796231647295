var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createReduxBlock } from "redux-sacala";
import { loadTypingSoundsList, loadTypingSound } from "api";
import { selectCurrentTypingSounds, selectTypingSoundsVolume } from "./selectors";
export const { actions: typingSounds, reducer: typingSoundsReducer, createMiddleware: typingSoundsMiddleware } = createReduxBlock()({
    name: "typingSounds",
    initial: {
        items: [],
        files: {}
    },
    actions: {
        receiveItems(state, items) {
            return Object.assign(Object.assign({}, state), { items });
        },
        receiveFiles(state, { id, files }) {
            return Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [id]: files }) });
        }
    },
    effects: (dispatch, getState) => {
        return {
            loadList() {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(typingSounds.receiveItems(yield loadTypingSoundsList()));
                });
            },
            loadFiles(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(typingSounds.receiveFiles({
                        id,
                        files: (yield Promise.all(["backspace", "return", "spacebar", "key-0", "key-1", "key-2", "key-3", "key-4", "key-5", "key-6"].map((key) => __awaiter(this, void 0, void 0, function* () {
                            return ({
                                key, file: yield loadTypingSound(id, key)
                            });
                        })))).reduce((r, item) => {
                            r[item.key] = item.file;
                            return r;
                        }, {})
                    }));
                });
            },
            play(keyCode) {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState(), sounds = selectCurrentTypingSounds(state), volume = selectTypingSoundsVolume(state), files = state.typingSounds.files;
                    if (sounds !== null && ![16, 17, 18].some(v => v === keyCode)) { // skip alt, shift, and ctrl
                        let file = null;
                        if (keyCode === 13) {
                            file = "return";
                        }
                        else if (keyCode === 8) {
                            file = "backspace";
                        }
                        else if (keyCode === 32) {
                            file = "spacebar";
                        }
                        else {
                            file = `key-${keyCode % 7}`;
                        }
                        if (file && files[sounds]) {
                            const audio = new Audio();
                            audio.volume = volume;
                            audio.preload = "auto";
                            audio.oncanplay = () => audio.play();
                            audio.src = files[sounds][file];
                        }
                    }
                });
            }
        };
    }
});
