var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
const iterations = 4;
// clientTime - serverTime
let diff = null;
function now() {
    return __awaiter(this, void 0, void 0, function* () {
        return (yield axios.get("/now")).data;
    });
}
export const serverTime = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!diff) {
        diff = (() => __awaiter(void 0, void 0, void 0, function* () {
            let time = 0, serverTime = 0;
            yield now(); // Possible cold start
            for (let i = 0; i < iterations; i++) {
                time += Date.now();
                serverTime += yield now();
                time += Date.now();
            }
            return (time / iterations * 0.5) - (serverTime / iterations);
        }))();
    }
    return Date.now() + Math.floor(yield diff);
});
