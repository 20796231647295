import * as React from "react";
import { DropDownMenu } from "ui/drop-down";
import { MenuItem } from "ui/menu";
import { VolumeSlider } from "ui/volume";
import styled from "styled-components";
const SliderNode = styled(VolumeSlider) `
margin: 0 8px 0 16px;
`;
function SoundSelectItem({ id, name, selected, onClick }) {
    const handleClick = React.useCallback(() => {
        onClick(id);
    }, [onClick, id]);
    return React.createElement(MenuItem, { selected: selected, onClick: handleClick }, name);
}
export const SoundSelect = React.memo(({ loading, volume, value, sounds, onSelectSound, onVolumeChange, style, className }) => {
    const off = React.useCallback(() => {
        onSelectSound(null);
    }, [onSelectSound]);
    return React.createElement(DropDownMenu, { style: style, className: className, loading: loading, label: value === null ? "Off" : sounds.find(v => v.id === value).name },
        React.createElement(SliderNode, { volume: volume, onChange: onVolumeChange }),
        React.createElement(MenuItem, { onClick: off, selected: value === null }, "Off"),
        sounds.map(({ id, name }) => {
            return React.createElement(SoundSelectItem, { key: id, id: id, name: name, selected: id === value, onClick: onSelectSound });
        }));
});
