var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from "styled-components";
import { keyframes } from "styled-components";
import { useEffect } from "react";
import * as React from "react";
export const rippleAnimationDuration = 500;
const rippleKeyframes = keyframes `
0% { opacity: 0; transform: translate(-50%, -50%) scale(0); }
50% { opacity: 1; transform: translate(-50%, -50%) scale(0.5); }
100% { opacity: 0; transform: translate(-50%, -50%); }
`;
const RippleNode = styled.div `
position: absolute;
left: ${({ x }) => x.toFixed(2)}px;
top: ${({ y }) => y.toFixed(2)}px;
width: ${({ size }) => size.toFixed(2)}px;
height: ${({ size }) => size.toFixed(2)}px;
opacity: 0;
pointer-events: none;
animation: ${rippleKeyframes} 0.3s linear;
border-radius: 50%;
background: ${({ color }) => color};
transform-origin: center center;
`;
export function Ripple(_a) {
    var { onRemove } = _a, props = __rest(_a, ["onRemove"]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            onRemove(props.rippleId);
        }, 300);
        return () => clearTimeout(timeout);
    }, [onRemove]);
    return React.createElement(RippleNode, Object.assign({}, props));
}
