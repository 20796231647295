var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import noop from "lodash-es/noop";
import { EventStream } from "./collection";
import { createQueue } from "lib/queue";
export class SettingsStorage {
    constructor(app) {
        this.app = app;
        this.enqueue = createQueue();
        this.onUpdate = new EventStream();
        this.document = null;
        this.unsubscribe = noop;
    }
    load() {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            return (yield this.document.get()).data();
        }));
    }
    save(settings) {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            yield this.document.set(settings);
        }));
    }
    snapshot() {
        return this.load();
    }
    setUser(userId, snapshot) {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            this.unsubscribe();
            this.document = this.app.firestore().doc(`/users/${userId}`);
            const stored = (yield this.document.get()).data();
            if (snapshot && !stored) {
                yield this.document.set(snapshot);
            }
            else {
                this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
                    this.onUpdate.trigger(stored);
                }));
            }
            this.unsubscribe = this.document.onSnapshot(snapshot => {
                if (snapshot.data() && !snapshot.metadata.hasPendingWrites) {
                    this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
                        this.onUpdate.trigger(snapshot.data());
                    }));
                }
            });
        }));
    }
}
