import { connect } from "react-redux";
import { FontSelect } from "ui/font-select";
import { selectFontSize, selectFontFamily, selectHotFonts, settings, dialog, menu, } from "state";
export const FontSelectorConnected = connect((state) => ({
    size: selectFontSize(state),
    font: selectFontFamily(state),
    fontList: selectHotFonts(state),
}), dispatch => ({
    onSizeChange: (size) => dispatch(settings.setFontSize(size)),
    onFontChange: (font) => dispatch(settings.setFontFamily(font)),
    onOpenDialog: () => {
        dispatch(menu.close());
        dispatch(dialog.chooseFont());
    }
}))(FontSelect);
