import { connect } from "react-redux";
import { selectCurrentDialog, DialogType, dialog, fonts, selectFonts, settings, } from "state";
import { FontsDialog } from "ui/font-dialog";
export const FontsDialogConnected = connect((state) => ({
    fonts: selectFonts(state),
    isOpen: selectCurrentDialog(state) === DialogType.SelectFont,
}), dispatch => ({
    onClose: () => dispatch(dialog.close()),
    onSelect: (font) => {
        dispatch(settings.setFontFamily(font));
        dispatch(dialog.close());
    },
    onPreloadRequest: (font) => dispatch(fonts.loadFont(font))
}))(FontsDialog);
