import * as React from "react";
import styled from "styled-components";
import { EntryType } from "state";
import { ListItem } from "ui/list";
import { IconButton } from "../button";
import { mdiPen, mdiDelete, mdiArrowUpBold, mdiFolder, mdiFile } from "@mdi/js";
import { TextInput } from "ui/text-input";
import Icon from "@mdi/react";
import { ThemeColor } from "ui/theme";
const FsEntryNode = styled(ListItem) `
align-items: center;
`;
const ButtonsContainer = styled.div `
display: flex;
margin: -8px 0;
`;
const LabelInput = styled(TextInput) `
flex-grow: 1;
margin: -8px 0 -12px 0;
cursor: ${({ readonly = false }) => readonly ? "pointer" : "text"};
`;
const EntryIcon = styled(Icon) `
margin: -8px 16px -8px 0;
flex-shrink: 0;
`;
const mime = "application/x-zenwriter-entry";
function idMime(id) {
    return "application/x-zenwriter-entry-id-" + id;
}
export const FsEntry = React.memo(({ isLinkToParent, entry, onRename, edit, onClick, onEdit, onDelete, onMove, onCopy }) => {
    const isFolder = entry.type === EntryType.Folder;
    const [displayValue, setDisplayValue] = React.useState(entry.name);
    React.useEffect(() => setDisplayValue(entry.name), [entry.name]);
    React.useEffect(() => {
        if (!edit && entry.name !== displayValue) {
            onRename(entry, displayValue);
        }
    }, [edit]);
    const handleClick = React.useCallback(() => {
        onClick(entry);
    }, [entry]);
    const handleEdit = React.useCallback(() => {
        onEdit(entry, true);
    }, [onEdit, entry, edit]);
    const handleDelete = React.useCallback(() => {
        onDelete(entry);
    }, [entry]);
    const handleEditEnd = React.useCallback(() => {
        edit && onEdit(entry, false);
    }, [edit, entry, onEdit]);
    const handleEditCancel = React.useCallback(() => {
        setDisplayValue(entry.name);
        onEdit(entry, false);
    }, [edit, entry, onEdit]);
    const handleDragStart = React.useCallback((e) => {
        e.dataTransfer.setData(mime, entry.id);
        e.dataTransfer.setData(idMime(entry.id), entry.id);
        e.dataTransfer.effectAllowed = "copyMove";
    }, [entry.id]);
    function getEffect(e) {
        const hasEntry = e.dataTransfer.types.includes(mime), sameEntry = e.dataTransfer.types.includes(idMime(entry.id));
        if (entry.type === EntryType.Folder && hasEntry) {
            if (sameEntry) {
                return (e.ctrlKey || e.metaKey) ? "copy" : "none";
            }
            else {
                return (e.ctrlKey || e.metaKey) ? "copy" : "move";
            }
            e.preventDefault();
        }
        else {
            return "none";
        }
    }
    const handleDragOver = React.useCallback((e) => {
        if (isFolder) {
            e.dataTransfer.dropEffect = getEffect(e);
            e.preventDefault();
        }
    }, [entry.id, isFolder]);
    const handleDrop = React.useCallback(event => {
        const itemId = event.dataTransfer.getData(mime);
        if (itemId && isFolder) {
            const effect = getEffect(event) === "copy" ? onCopy : onMove;
            effect(itemId, isLinkToParent ? entry.parent : entry.id);
        }
    }, [isLinkToParent, isFolder, entry, onMove, onCopy]);
    const icon = isLinkToParent ? mdiArrowUpBold :
        isFolder ? mdiFolder :
            mdiFile;
    return React.createElement("div", { draggable: !isLinkToParent, onDragStart: handleDragStart, onDragOver: handleDragOver, onDrop: handleDrop }, isLinkToParent
        ? React.createElement(FsEntryNode, { onClick: handleClick },
            React.createElement("span", null,
                React.createElement(EntryIcon, { size: "24px", color: ThemeColor.SecondaryText, path: icon }),
                ".."))
        : React.createElement(FsEntryNode, { active: !edit, onClick: handleClick },
            React.createElement(EntryIcon, { size: "24px", color: ThemeColor.SecondaryText, path: icon }),
            React.createElement(LabelInput, { autofocus: edit, onChange: setDisplayValue, value: displayValue, placeholder: "Untitled", readonly: !edit, onBlur: handleEditEnd, onEnter: handleEditEnd, onEscape: handleEditCancel }),
            React.createElement(ButtonsContainer, null,
                React.createElement(IconButton, { icon: mdiPen, onClick: handleEdit }),
                React.createElement(IconButton, { icon: mdiDelete, onClick: handleDelete }))));
});
FsEntry.displayName = "FsEntry";
