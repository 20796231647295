import * as React from "react";
import styled from "styled-components";
import { ThemeColor } from "ui/theme";
const LabelContainer = styled.div `
margin-top: 20px;
:first-child {
    margin-top: 0;
}
`;
LabelContainer.displayName = "LabelContainer";
const LabelCaption = styled.div `
font-size: 13px;
color: ${ThemeColor.SecondaryText};
user-select: none;
`;
LabelCaption.displayName = "LabelCaption";
export function Label({ className, children, caption }) {
    return React.createElement(LabelContainer, { className: className },
        React.createElement(LabelCaption, null, caption),
        children);
}
