import * as React from "react";
import * as ReactDOM from "react-dom";
import debounce from "lodash-es/debounce";
import styled from "styled-components";
import { Plugin } from "prosemirror-state";
import { toggleMark } from "prosemirror-commands";
import { mdiFormatBold, mdiFormatItalic, mdiFormatUnderline, mdiFormatAlignLeft, mdiFormatAlignRight, mdiFormatAlignJustify, mdiFormatAlignCenter } from "@mdi/js";
import { shadow, ThemeColor, ThemeProvider } from "ui/theme";
import { DropDownMenu } from "ui/drop-down";
import { MenuItem } from "ui/menu";
import { IconButton } from "ui/button";
import { schema, getBlockType, BlockType, BlockAlign, alignBlock, setBlockType } from "state/schema";
import { Theme } from "state/theme";
const Panel = styled.div `
border-radius: 2px;
box-shadow: ${shadow(16)};
background: ${ThemeColor.PaperLight};
display: flex;
flex-direction: row;
align-items: center;
`;
const BlockTypeSelector = styled(DropDownMenu) `
min-width: 150px;
& > div {
    border-bottom: none;
}
`;
const IndentItem = styled.div `
margin-right: 8px;
`;
function IndentEach({ children }) {
    return React.createElement(React.Fragment, null, React.Children.map(children, c => React.createElement(IndentItem, null, c)));
}
class FormattingPanel extends React.Component {
    constructor() {
        super(...arguments);
        this.preventDefault = (e) => e.preventDefault();
        this.setNodeTypeParagraph = () => {
            const { state, dispatch } = this.props;
            setBlockType(BlockType.Paragraph)(state, dispatch);
        };
        this.setNodeTypeChapter = () => {
            const { state, dispatch } = this.props;
            setBlockType(BlockType.Chapter)(state, dispatch);
        };
        this.toggleBold = () => {
            const { state, dispatch } = this.props;
            toggleMark(schema.marks.b)(state, dispatch);
        };
        this.toggleItalic = () => {
            const { state, dispatch } = this.props;
            toggleMark(schema.marks.i)(state, dispatch);
        };
        this.toggleUnderline = () => {
            const { state, dispatch } = this.props;
            toggleMark(schema.marks.u)(state, dispatch);
        };
        this.alignLeft = () => {
            const { state, dispatch } = this.props;
            alignBlock(BlockAlign.Left)(state, dispatch);
        };
        this.alignRight = () => {
            const { state, dispatch } = this.props;
            alignBlock(BlockAlign.Right)(state, dispatch);
        };
        this.alignCenter = () => {
            const { state, dispatch } = this.props;
            alignBlock(BlockAlign.Center)(state, dispatch);
        };
        this.alignJustify = () => {
            const { state, dispatch } = this.props;
            alignBlock(BlockAlign.Justify)(state, dispatch);
        };
    }
    render() {
        const { state } = this.props, blockType = getBlockType(state);
        return React.createElement(Panel, { onClick: this.preventDefault, onMouseDown: this.preventDefault },
            React.createElement(IndentEach, null,
                React.createElement(BlockTypeSelector, { label: BlockType.getLabel(blockType) },
                    React.createElement(MenuItem, { selected: blockType === BlockType.Paragraph, onClick: this.setNodeTypeParagraph }, BlockType.getLabel(BlockType.Paragraph)),
                    React.createElement(MenuItem, { selected: blockType === BlockType.Chapter, onClick: this.setNodeTypeChapter }, BlockType.getLabel(BlockType.Chapter))),
                React.createElement(IconButton, { icon: mdiFormatBold, onClick: this.toggleBold }),
                React.createElement(IconButton, { icon: mdiFormatItalic, onClick: this.toggleItalic }),
                React.createElement(IconButton, { icon: mdiFormatUnderline, onClick: this.toggleUnderline }),
                React.createElement(IconButton, { icon: mdiFormatAlignJustify, onClick: this.alignJustify }),
                React.createElement(IconButton, { icon: mdiFormatAlignLeft, onClick: this.alignLeft }),
                React.createElement(IconButton, { icon: mdiFormatAlignCenter, onClick: this.alignCenter }),
                React.createElement(IconButton, { icon: mdiFormatAlignRight, onClick: this.alignRight })));
    }
}
function PanelConnector({ subscribe, view }) {
    const [state, setState] = React.useState(view.state);
    React.useEffect(() => subscribe(setState), []);
    return React.createElement(FormattingPanel, { state: state, dispatch: view.dispatch });
}
export const formattingPanelPlugin = new Plugin({
    view(view) {
        const tooltip = document.createElement("div");
        let update = () => undefined;
        view.dom.parentNode.appendChild(tooltip);
        ReactDOM.render(React.createElement(ThemeProvider, { theme: Theme.Light },
            React.createElement(PanelConnector, { subscribe: (callback) => update = callback, view: view })), tooltip);
        Object.assign(tooltip.style, {
            position: "absolute",
            transition: "opacity 0.15s",
            opacity: "0",
            pointerEvents: "none"
        });
        const hide = () => {
            tooltip.style.opacity = "0";
            tooltip.style.pointerEvents = "none";
        }, show = debounce((state) => {
            const { from, to } = state.selection, start = view.coordsAtPos(from), end = view.coordsAtPos(to), box = tooltip.offsetParent.getBoundingClientRect(), left = (start.left + end.left) / 2 - tooltip.getBoundingClientRect().width / 2;
            update(state);
            tooltip.style.left = (left - box.left) + "px";
            tooltip.style.bottom = (box.bottom - start.top + 16) + "px";
            tooltip.style.opacity = "1";
            tooltip.style.pointerEvents = "auto";
        }, 300);
        return {
            update(view) {
                const state = view.state;
                if (state.selection.empty) {
                    hide();
                }
                else {
                    show(state);
                }
            },
            destroy() {
                ReactDOM.unmountComponentAtNode(tooltip);
                tooltip.remove();
            }
        };
    }
});
