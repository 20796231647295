import * as React from "react";
import { DropDownMenu } from "ui/drop-down";
import { MenuItem } from "ui/menu";
function BackgroundMenuItem({ id, name, selected, onClick }) {
    const clickHandler = React.useCallback(() => onClick(id), [id, onClick]);
    return React.createElement(MenuItem, { selected: selected, onClick: clickHandler }, name);
}
export function BackgroundSelector({ value, items, onSelect, className, loading }) {
    const selectedItem = items.find(v => v.id === value);
    return React.createElement(DropDownMenu, { className: className, label: selectedItem ? selectedItem.name : "Off", loading: loading }, items.map(item => React.createElement(BackgroundMenuItem, { key: item.id, id: item.id, name: item.name, selected: item.id === value, onClick: onSelect })));
}
