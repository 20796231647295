import * as React from "react";
import styled from "styled-components";
import { IconButton } from "ui/button";
import Icon from "@mdi/react";
import { mdiGoogle, mdiFacebookBox, mdiAccount, mdiLogout } from "@mdi/js";
import { ThemeColor } from "ui/theme";
import { Menu, MenuItem } from "ui/menu";
const LoginButtonWrapper = styled.div `position: relative;`;
const LoginIcon = styled(Icon) `
margin-right: 24px;
width: 24px;
height: 24px;
`;
const LoginMenu = styled(Menu) `
position: absolute;
transform-origin: right top;
right: 0;
top: 0;
z-index: 1;
`;
export function ProfileButton({ className, isAnonimous, onGoogleLogin, onFacebookLogin, onLogout, }) {
    const [open, setOpen] = React.useState(false), openMenu = React.useCallback(() => setOpen(true), [setOpen]), hideMenu = React.useCallback(() => setOpen(false), [setOpen]);
    return React.createElement(LoginButtonWrapper, { className: className },
        React.createElement(IconButton, { icon: mdiAccount, onClick: openMenu }),
        React.createElement(LoginMenu, { open: open, onHide: hideMenu }, isAnonimous ? React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { onClick: onGoogleLogin },
                React.createElement(LoginIcon, { color: ThemeColor.SecondaryText, path: mdiGoogle }),
                "Sign in with Google"),
            React.createElement(MenuItem, { onClick: onFacebookLogin },
                React.createElement(LoginIcon, { color: ThemeColor.SecondaryText, path: mdiFacebookBox }),
                "Sign in with Facebook")) : React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { onClick: onLogout },
                React.createElement(LoginIcon, { color: ThemeColor.SecondaryText, path: mdiLogout }),
                "Sign Out"))));
}
