import * as React from "react";
import styled from "styled-components";
import { Icon } from "@mdi/react";
import { mdiMenuDown } from "@mdi/js";
import { BaseButton, RippleOrigin } from "ui/button";
import { ThemeColor } from "ui/theme";
import { Menu } from "ui/menu";
import { Loading } from "ui/loading";
const DropDownContainer = styled.div `
position: relative;
`;
DropDownContainer.displayName = "DropDownContainer";
const DropDownButton = styled(BaseButton) `
position: relative;
overflow: hidden;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
padding: 11px 8px 10px 8px;
border-bottom: solid 1px ${ThemeColor.Border};
font-size: 16px;
line-height: 16px;
white-space: nowrap;
text-overflow: ellipsis;
`;
DropDownButton.displayName = "DropDownButton";
const DropDownNode = styled(Menu) `
position: absolute;
left: 0;
top: 0;
right: 0;
transform-origin: center top;
z-index: 1;
`;
DropDownNode.displayName = "DropDownNode";
const DropDownLoading = styled(Loading) `
position: absolute;
bottom: 0;
left: 0;
right: 0;
`;
DropDownLoading.displayName = "DropDownLoading";
export function DropDownMenu({ label, children, style, className, loading = false }) {
    const [open, setOpen] = React.useState(false);
    const hide = React.useCallback(() => setOpen(false), [setOpen]);
    const show = React.useCallback(() => setOpen(true), [setOpen]);
    return React.createElement(DropDownContainer, { style: style, className: className },
        React.createElement(DropDownButton, { rippleOrigin: RippleOrigin.MOUSE, rippleColor: ThemeColor.Border, onClick: show },
            label,
            React.createElement(Icon, { color: ThemeColor.SecondaryText, path: mdiMenuDown, style: {
                    width: 24,
                    height: 24
                } })),
        React.createElement(DropDownLoading, { loading: loading }),
        React.createElement(DropDownNode, { open: open, onHide: hide }, children));
}
