var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createReduxBlock } from "redux-sacala";
import { EditorState, Transaction } from "prosemirror-state";
import { history, undo, redo } from "prosemirror-history";
import { schema, hardBreak } from "./schema";
import { toggleMark, baseKeymap } from "prosemirror-commands";
import { keymap } from "./editor-plugins/keymap";
import { inputRules, openDoubleQuote, closeDoubleQuote, openSingleQuote, closeSingleQuote, ellipsis, emDash } from "prosemirror-inputrules";
import { formattingPanelPlugin } from "./editor-plugins/formatting-panel";
import { convertToTxt, convertToPdf, convertToHtml, convertToDocx } from "./convertors";
import { saveAs } from "file-saver";
import { selectCurrentDocumentEntry, selectCurrentDocumentId, selectModificationTime, selectEditorBody, selectEditor, selectEditorTitle } from "./selectors";
import { serverTime, createListenerMiddleware } from "api";
import debounce from "lodash-es/debounce";
import { textCounter } from "./text-counters";
const allInputRules = [
    openDoubleQuote,
    closeDoubleQuote,
    openSingleQuote,
    closeSingleQuote,
    ellipsis,
    emDash
];
const setNodeType = (type) => (state, dispatch) => {
    const { from, to } = state.selection;
    let transaction = state.tr;
    state.doc.nodesBetween(from, to, (node, pos) => {
        if ([schema.nodes.paragraph, schema.nodes.subheader].indexOf(node.type) !== -1) {
            transaction = transaction.setNodeMarkup(pos, type, node.attrs, node.marks);
        }
        return false;
    });
    dispatch && dispatch(transaction);
    return true;
};
function createEditorState(data = null) {
    const plugins = [
        history(),
        inputRules({ rules: allInputRules }),
        keymap(Object.assign(Object.assign({}, baseKeymap), { "Tab": (state, dispatch) => {
                dispatch && dispatch(new Transaction(state).insertText("\u0009"));
                return true;
            }, "Mod-Shift-z": redo, "Mod-b": toggleMark(schema.marks.b), "Mod-i": toggleMark(schema.marks.i), "Mod-u": toggleMark(schema.marks.u), "Ctrl-Enter": hardBreak, "Shift-Enter": hardBreak, "Mod-z": undo, "Mod-y": redo })),
        formattingPanelPlugin
    ];
    return data
        ? EditorState.fromJSON({ schema, plugins }, data)
        : EditorState.create({ schema, plugins });
}
export const { actions: editor, reducer: editorReducer, createMiddleware: editorMiddleware } = createReduxBlock()({
    name: "editor",
    initial: null,
    actions: {
        applyTransaction(state, { transaction, time }) {
            if (state) {
                return {
                    content: state.content.apply(transaction),
                    updated: time,
                    saved: state.saved
                };
            }
            else {
                return state;
            }
        },
        openContent: (_, { content, time }) => {
            return {
                content,
                updated: time,
                saved: time
            };
        },
        closeContent: () => null,
        markSaved: (state, time) => state ? (Object.assign(Object.assign({}, state), { saved: Math.max(time, state.saved) })) : null,
    },
    effects: (dispatch, getState) => {
        return {
            watch(_, database) {
                database.documents.onExternalUpdate.on(({ id, document, time }) => __awaiter(this, void 0, void 0, function* () {
                    const state = getState();
                    if (selectCurrentDocumentId(state) === id
                        && selectModificationTime(state) < time) {
                        dispatch(editor.openContent({
                            time, content: createEditorState(document)
                        }));
                    }
                }));
            },
            applyComand({ command, time }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState().editor, commandTime = time || (yield serverTime());
                    if (state) {
                        command(state.content, transaction => {
                            dispatch(editor.applyTransaction({ transaction, time: commandTime }));
                        });
                    }
                });
            },
            setSubheader(time) {
                dispatch(editor.applyComand({
                    time, command: setNodeType(schema.nodes.subheader)
                }));
            },
            setParagraph(time) {
                dispatch(editor.applyComand({
                    time, command: setNodeType(schema.nodes.paragraph)
                }));
            },
            toggleBold(time) {
                dispatch(editor.applyComand({
                    time, command: toggleMark(schema.marks.b)
                }));
            },
            toggleUnderline(time) {
                dispatch(editor.applyComand({
                    time, command: toggleMark(schema.marks.u)
                }));
            },
            toggleItalic(time) {
                dispatch(editor.applyComand({
                    time, command: toggleMark(schema.marks.i)
                }));
            },
            // Open / Close / Save
            open(documentId, database) {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState();
                    dispatch(editor.close());
                    const d = yield database.documents.load(documentId), time = yield serverTime();
                    dispatch(editor.openContent({
                        time,
                        content: createEditorState(d)
                    }));
                });
            },
            close() {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(editor.save());
                    dispatch(editor.closeContent());
                });
            },
            save(_, database) {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState(), e = state.editor, id = selectCurrentDocumentId(state);
                    if (e && e.updated !== e.saved && id) {
                        yield database.documents.save(id, e.content.toJSON(), e.updated);
                        if (selectCurrentDocumentId(getState()) === id) {
                            dispatch(editor.markSaved(e.updated));
                        }
                    }
                });
            },
            rename(name, database) {
                return __awaiter(this, void 0, void 0, function* () {
                    const entry = selectCurrentDocumentEntry(getState());
                    if (entry && entry.name !== name) {
                        yield database.entries.update(Object.assign(Object.assign({}, entry), { name }));
                    }
                });
            },
            // Conversion
            downloadAsTxt() {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState(), entry = selectCurrentDocumentEntry(state);
                    saveAs(convertToTxt(state), ((entry && entry.name) || "Untitled") + ".txt");
                });
            },
            downloadAsPdf() {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState(), entry = selectCurrentDocumentEntry(state);
                    saveAs(yield convertToPdf(state), ((entry && entry.name) || "Untitled") + ".pdf");
                });
            },
            downloadAsHtml() {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState(), entry = selectCurrentDocumentEntry(state);
                    saveAs(convertToHtml(state), ((entry && entry.name) || "Untitled") + ".html");
                });
            },
            downloadAsDocx() {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState(), entry = selectCurrentDocumentEntry(state);
                    saveAs(yield convertToDocx(state), ((entry && entry.name) || "Untitled") + ".docx");
                });
            }
        };
    }
});
export const saveDocumentMiddleware = (api) => (next) => {
    const save = debounce(() => {
        api.dispatch(editor.save());
    }, 1000);
    return (action) => {
        const initialDocument = api.getState().editor;
        next(action);
        if (initialDocument !== api.getState().editor) {
            save();
        }
    };
};
export const updateCountersMiddleware = createListenerMiddleware(selectEditor, debounce(api => {
    const state = api.getState();
    api.dispatch(textCounter.recalculate({
        title: selectEditorTitle(state) || "",
        body: selectEditorBody(state)
    }));
}, 1000));
