var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { connect } from "react-redux";
import { Editor } from "ui/editor";
import { selectEditorTitle, editor, selectEditorBody, selectFontSize, selectFirstLineIndent, selectParagraphSpacing, selectFontFamily, typingSounds, selectLineSpacing, typing } from "state";
import { serverTime } from "api";
export const EditorConnected = connect((state) => ({
    fontFamily: selectFontFamily(state),
    fontSize: selectFontSize(state),
    title: selectEditorTitle(state),
    state: selectEditorBody(state),
    paragraphSpacing: selectParagraphSpacing(state),
    lineSpacing: selectLineSpacing(state),
    firstLineIndent: selectFirstLineIndent(state)
}), (dispatch) => ({
    onTitleChange: (value) => {
        dispatch(editor.rename(value || ""));
    },
    onTransaction: (transaction) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(editor.applyTransaction({
            transaction,
            time: yield serverTime(),
        }));
    }),
    onKeyDown: (keyCode) => {
        dispatch(typingSounds.play(keyCode));
        dispatch(typing.enable());
    }
}))(Editor);
EditorConnected.displayName = "EditorConnected";
