import { createSelector } from "reselect";
import { MenuItem, ActiveScreen, EntryType } from "./state";
import { Collection } from "api/collections";
const compare = require("string-natural-compare");
export function selectSettings(state) { return state.settings; }
export function selectBackgrounds(state) { return state.backgrounds; }
export function selectMenu(state) { return state.menu; }
export function selectEditor(state) { return state.editor; }
export function selectFonts(state) { return state.fonts; }
export function selectTrackList(state) { return state.music.tracks; }
export function selectTypingSounds(state) { return state.typingSounds; }
export function selectTypingSoundsList(state) { return state.typingSounds.items; }
export function selectCurrentDialog(state) { return state.dialog; }
export function selectUser(state) { return state.user; }
export function selectEntries(state) { return state.entries; }
export function selectIsVisible(state) { return state.isVisible; }
export function selectIsTypingMode(state) { return state.typing && state.settings.activeScreen === ActiveScreen.Document; }
export function selectScreenWidth(state) { return state.screen.width; }
export function selectScreenHeight(state) { return state.screen.height; }
export function selectFullscreen(state) { return state.fullscreen; }
export function selectTextCounters(state) { return state.textCounters; }
export const selectEntriesItems = createSelector(selectEntries, entries => Collection.values(entries.items));
export const selectTheme = createSelector(selectSettings, settings => settings.theme);
export const selectCurrentTrack = createSelector(selectSettings, ({ music }) => music);
export const selectMusicVolume = createSelector(selectSettings, selectIsVisible, ({ musicVolume }, isVisible) => isVisible ? musicVolume : 0);
export const selectCurrentTypingSounds = createSelector(selectSettings, ({ typingSounds }) => typingSounds);
export const selectTypingSoundsVolume = createSelector(selectSettings, ({ typingSoundsVolume }) => typingSoundsVolume);
export const selectCurrentBackgroundId = createSelector(selectSettings, ({ background }) => background);
export const selectFontFamily = createSelector(selectSettings, ({ fontFamily }) => fontFamily);
export const selectFontSize = createSelector(selectSettings, ({ fontSize }) => fontSize);
export const selectFirstLineIndent = createSelector(selectSettings, ({ firstLineIndent }) => firstLineIndent);
export const selectParagraphSpacing = createSelector(selectSettings, ({ paragraphSpacing }) => paragraphSpacing);
export const selectLineSpacing = createSelector(selectSettings, ({ lineSpacing }) => lineSpacing);
export const selectBackgroundsList = createSelector(selectBackgrounds, backgrounds => backgrounds.items);
export const selectCurrentBackgroundFile = createSelector(selectCurrentBackgroundId, selectBackgrounds, (id, backgrounds) => (id && backgrounds.files[id]) || null);
export const selectCurrentFolderId = createSelector(selectSettings, selectEntries, ({ currentFolderId }, { items }) => {
    return currentFolderId && items.hasOwnProperty(currentFolderId)
        ? currentFolderId
        : null;
});
export const selectCurrentDocumentId = createSelector(selectSettings, selectEntries, ({ currentDocumentId }, entries) => {
    return currentDocumentId && entries.items[currentDocumentId]
        ? currentDocumentId
        : null;
});
export const selectModificationTime = createSelector(selectEditor, e => e ? e.updated : 0);
export const selectSaveTime = createSelector(selectEditor, e => e ? e.saved : 0);
export const selectCurrentDocumentEntry = createSelector(selectEntries, selectCurrentDocumentId, ({ items }, id) => id ? items[id] : null);
export const selectBackgroundLoading = createSelector(selectCurrentBackgroundId, selectCurrentBackgroundFile, (id, file) => id !== null && file === null);
export const selectTypingSoundsLoading = createSelector(selectTypingSounds, selectCurrentTypingSounds, (sounds, current) => current !== null && !sounds.files[current]);
export const selectAllFonts = createSelector(selectFonts, list => list.map(v => v.family));
export const selectHotFonts = createSelector(selectFontFamily, selectAllFonts, (currentFont, allFonts) => Array.from(new Set([
    currentFont,
    "Alegreya",
    "Merriweather",
    "Roboto",
    "Noto Sans",
    "Noto Serif"
])).filter(f => allFonts.indexOf(f) !== -1));
export const selectIsSettingsOpen = createSelector(selectMenu, menu => menu === MenuItem.Settings);
export const selectEditorTitle = createSelector(selectCurrentDocumentEntry, entry => entry && entry.name);
export const selectEditorBody = createSelector(selectEditor, editor => editor && editor.content);
export const selectActiveScreen = createSelector(selectSettings, selectEditorBody, ({ activeScreen }, currentDocument) => {
    return currentDocument ? activeScreen : ActiveScreen.Library;
});
export const selectUserId = createSelector(selectUser, user => user ? user.id : undefined);
export const selectIsAnonimous = createSelector(selectUser, user => user ? user.isAnonimous : false);
export const selectCurrentEntry = createSelector(selectEntries, selectCurrentFolderId, ({ items }, id) => {
    return (id && items[id]) || null;
});
export const selectCurrentEntries = createSelector(selectEntriesItems, selectCurrentFolderId, (entries, folder) => entries.filter(entry => (entry.parent || null) === folder).sort((e1, e2) => {
    if (e1.type !== e2.type) {
        return e1.type === EntryType.Folder ? -1 : 1;
    }
    else if (e1.name && e2.name) {
        return compare(e1.name.toLocaleLowerCase() + "|" + e1.id, e2.name.toLowerCase() + "|" + e2.id);
    }
    else if (!e1.name && !e2.name) {
        return e1.created - e2.created;
    }
    else if (!e1.name) {
        return 1;
    }
    else {
        return -1;
    }
}));
export const selectEditedEntryId = createSelector(selectEntries, selectEntries, ({ editedId }, { items }) => editedId && items.hasOwnProperty(editedId) ? editedId : null);
export const selectCurrentEntryLevel = createSelector(selectEntries, selectCurrentFolderId, ({ items }, folderId) => {
    const count = (id) => id ? count(items[id].parent) + 1 : 0;
    return count(folderId);
});
export const selectTimeToRead = createSelector(selectTextCounters, ({ words }) => {
    const seconds = words / 200 * 60;
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 3600 % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = (s > 0 || seconds < 1) ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
});
