import { connect } from "react-redux";
import { CountersButton } from "ui/counters-button";
import { selectTimeToRead } from "state";
const linesPerPage = 30;
export const CountersButtonConnected = connect((state) => ({
    chars: state.textCounters.chars,
    nonSpaceChars: state.textCounters.nonSpaceChars,
    words: state.textCounters.words,
    lines: state.textCounters.lines,
    pages: Math.ceil(state.textCounters.lines / linesPerPage),
    timeToRead: selectTimeToRead(state)
}))(CountersButton);
