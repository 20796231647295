import { connect } from "react-redux";
import { SoundSelect } from "ui/sound-select";
import { selectCurrentTypingSounds, selectTypingSoundsVolume, selectTypingSoundsList, settings, selectTypingSoundsLoading } from "state";
export const TypingSoundsSelectorConnected = connect((state) => ({
    value: selectCurrentTypingSounds(state),
    volume: selectTypingSoundsVolume(state),
    sounds: selectTypingSoundsList(state),
    loading: selectTypingSoundsLoading(state)
}), dispatch => ({
    onSelectSound: (sounds) => {
        dispatch(settings.setTypingSounds(sounds));
    },
    onVolumeChange: (volume) => {
        dispatch(settings.setTypingSoundsVolume(volume));
    }
}))(SoundSelect);
