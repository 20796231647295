import * as React from "react";
import styled from "styled-components";
import { ThemeColor } from "ui/theme";
const SliderLine = styled.div `
position: relative;
height: 2px;
`;
const SliderLineIndents = styled.div `
background: ${ThemeColor.Border};
height: 2px;
border-right: solid 6px transparent;
border-left: solid 6px ${ThemeColor.Accent};
box-sizing: border-box;
width: 100%;
`;
const Progress = styled.div `
position: relative;
background: ${ThemeColor.Accent};
height: 100%;
`;
const Point = styled.div `
width: 12px;
height: 12px;
background: ${ThemeColor.Accent};
border-radius: 50%;
position: absolute;
right: -6px;
top: -5px;
will-change: transform;
transition: transform 0.3s;
`;
const Focus = styled.div `
width: 36px;
height: 36px;
background: ${ThemeColor.Accent};
opacity: 0.2;
position: absolute;
right: -18px;
top: -17px;
border-radius: 50%;
transform: scale(0);
will-change: transform;
transition: transform 0.3s;
`;
const SliderContainer = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-grow: 1;
flex-shrink: 1;
height: 36px;
cursor: pointer;
outline: none;
user-select: none;
-webkit-touch-callout: none;
-webkit-tap-highlight-color: transparent;
:focus ${Focus} {
    transform: scale(1);
}
:active ${Focus} {
    transform: scale(0);
}
:active ${Point} {
    transform: scale(1.5);
}
`;
export const Slider = React.memo(({ value, min, max, isInteger, onChange, className, style }) => {
    const progress = (value - min) / (max - min) * 100;
    const [active, setActive] = React.useState(null);
    const [node, setNode] = React.useState(null);
    const handleMove = React.useCallback((x) => {
        const bounds = node.firstChild.getBoundingClientRect();
        if (x <= bounds.left) {
            onChange(min);
        }
        else if (x >= bounds.right) {
            onChange(max);
        }
        else {
            const v = (x - bounds.left) / bounds.width * (max - min) + min;
            onChange(isInteger ? Math.min(max, Math.max(min, Math.round(v))) : v);
        }
    }, [value, min, max, isInteger, onChange, node]);
    const mouseDownHandler = React.useCallback((e) => {
        if (active === null && e.button === 0) {
            node.focus();
            handleMove(e.clientX);
            e.preventDefault();
            setActive(-1);
        }
    }, [handleMove, active]);
    const mouseMoveHandler = React.useCallback((e) => {
        if (active === -1) {
            handleMove(e.clientX);
            e.preventDefault();
        }
    }, [handleMove, active]);
    const mouseUpHandler = React.useCallback((e) => {
        if (active === -1 && e.button === 0) {
            handleMove(e.clientX);
            e.preventDefault();
            setActive(null);
        }
    }, [handleMove, active]);
    const touchStartHandler = React.useCallback((e) => {
        if (active === null) {
            e.currentTarget.focus();
            handleMove(e.touches[0].clientX);
            setActive(e.touches.item(0).identifier);
        }
    }, [handleMove, active]);
    const touchChangeHandler = React.useCallback((e) => {
        const touch = Array.from(e.changedTouches).find(t => t.identifier === active);
        if (touch) {
            handleMove(touch.clientX);
        }
    }, [handleMove, active]);
    const touchEndHandler = React.useCallback((e) => {
        const touch = Array.from(e.changedTouches).find(t => t.identifier === active);
        if (touch) {
            handleMove(touch.clientX);
            setActive(null);
        }
    }, [handleMove, active]);
    React.useEffect(() => {
        if (node && active === -1) {
            window.addEventListener("mousemove", mouseMoveHandler);
            window.addEventListener("mouseup", mouseUpHandler);
            return () => {
                window.removeEventListener("mousemove", mouseMoveHandler);
                window.removeEventListener("mouseup", mouseUpHandler);
            };
        }
    }, [mouseMoveHandler, mouseUpHandler, active, node]);
    return React.createElement(SliderContainer, { ref: setNode, tabIndex: 0, onMouseDown: mouseDownHandler, onTouchStart: touchStartHandler, onTouchMove: touchChangeHandler, onTouchEnd: touchEndHandler, onTouchCancel: touchEndHandler, className: className, style: style },
        React.createElement(SliderLineIndents, null,
            React.createElement(SliderLine, null,
                React.createElement(Progress, { style: { width: progress.toFixed(4) + "%" } },
                    React.createElement(Focus, null),
                    React.createElement(Point, null)))));
});
