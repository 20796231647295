import * as React from "react";
import { ThemeColor } from "ui/theme";
function opacity(min, max, value) {
    if (value >= max) {
        return 1;
    }
    else if (value <= min) {
        return 0;
    }
    else {
        return (value - min) / (max - min);
    }
}
export function VolumeIcon({ className, style, volume }) {
    return React.createElement("svg", { className: className, style: Object.assign(Object.assign({}, style), { width: 24, height: 24 }), viewBox: "0 0 24 24" },
        React.createElement("path", { fill: ThemeColor.SecondaryText, d: "M3,9V15H7L12,20V4L7,9H3Z" }),
        React.createElement("path", { opacity: 1 - opacity(0, 0.1, volume), fill: ThemeColor.SecondaryText, d: "M16.59,12L14,9.41L15.41,8L18,10.59L20.59,8L22,9.41L19.41,12L22,14.59L20.59,16L18,13.41L15.41,16L14,14.59L16.59,12Z" }),
        React.createElement("path", { opacity: opacity(0.1, 0.5, volume), fill: ThemeColor.SecondaryText, d: "M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12" }),
        React.createElement("path", { opacity: opacity(0.5, 1, volume), fill: ThemeColor.SecondaryText, d: "M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23" }));
}
