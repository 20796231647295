import * as React from "react";
import { IconButton } from "ui/button";
import { mdiMenu } from "@mdi/js";
import { connect } from "react-redux";
import { menu } from "state";
function SettingsButton({ onClick }) {
    return React.createElement(IconButton, { icon: mdiMenu, onClick: onClick });
}
export const SettingsButtonConnected = connect(() => ({}), dispatch => ({
    onClick() { dispatch(menu.openSettings()); }
}))(SettingsButton);
SettingsButtonConnected.displayName = "SettingsButtonConnected";
