import * as React from "react";
import styled from "styled-components";
import { ThemeColor } from "ui/theme";
const TitleInputNode = styled.input `
color: ${ThemeColor.PrimaryText};
font-size: ${({ baseFontSize }) => (baseFontSize * 1.2).toFixed(2)}px;
border: none;
text-overflow: ellipsis;
padding: 0 0 0.5em 0;
margin: 0 0 1em 0px;
letter-spacing: 0.2em;
display: block;
outline: none;
background: transparent;
text-align: center;
flex-grow: 0;
flex-shrink: 0;
transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
font-family: "Times New Roman", serif;
`;
export function TitleInput({ title, onChange, onPressEnter, className, baseFontSize, placeholder, autofocus }) {
    const [displayValue, setDisplayValue] = React.useState(title);
    const node = React.useRef(null);
    React.useEffect(() => setDisplayValue(title), [title]);
    React.useEffect(() => {
        node.current && node.current.focus();
    }, [autofocus]);
    const handleChange = React.useCallback((e) => {
        setDisplayValue(e.currentTarget.value);
    }, []);
    const handleKeydown = React.useCallback((e) => {
        if (e.keyCode === 13) {
            onChange(displayValue);
            onPressEnter();
            e.preventDefault();
            e.stopPropagation();
        }
    }, [onChange, onPressEnter, displayValue]);
    const handleBlur = React.useCallback(() => {
        onChange(displayValue);
    }, [displayValue, onChange]);
    React.useEffect(() => {
        return () => {
            setDisplayValue(value => {
                onChange(value);
                return value;
            });
        };
    }, [onChange]);
    return React.createElement(TitleInputNode, { ref: node, value: displayValue, placeholder: placeholder, onChange: handleChange, baseFontSize: baseFontSize, className: className, onKeyDown: handleKeydown, onBlur: handleBlur });
}
