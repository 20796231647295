import * as React from "react";
import { connect } from "react-redux";
import { selectIsSettingsOpen, menu, selectParagraphSpacing, settings, selectFirstLineIndent, selectLineSpacing } from "state";
import { Drawer, DrawerSide } from "ui/drawer";
import { Label } from "ui/label";
import { Slider } from "ui/slider";
import { ThemeSelectorConnected } from "./theme-selector";
import { BackgroundSelectorConnected } from "./background-selector";
import { FontSelectorConnected } from "./font-selector";
import { MusicSelectorConnected } from "./music-selector";
import { TypingSoundsSelectorConnected } from "./typing-sounds-selector";
const SettingsDrawerConnected = connect((state) => ({
    open: selectIsSettingsOpen(state)
}), (dispatch) => ({
    onHide() { dispatch(menu.close()); }
}))(Drawer);
const ParagraphSpacingConnected = connect((state) => ({
    isInteger: true,
    min: 0,
    max: 10,
    value: selectParagraphSpacing(state) * 4
}), dispatch => ({
    onChange(spacing) {
        dispatch(settings.setParagraphSpacing(spacing / 4));
    }
}))(Slider);
const LineSpacingConnected = connect((state) => ({
    isInteger: true,
    min: 4,
    max: 10,
    value: selectLineSpacing(state) * 4
}), dispatch => ({
    onChange(spacing) {
        dispatch(settings.setLineSpacing(spacing / 4));
    }
}))(Slider);
const FirstLineIndentConnected = connect((state) => ({
    isInteger: true,
    min: 0,
    max: 6,
    value: selectFirstLineIndent(state)
}), dispatch => ({
    onChange(indent) {
        dispatch(settings.setFirstLineIndent(indent));
    }
}))(Slider);
export function SettingsConnected() {
    return React.createElement(SettingsDrawerConnected, { side: DrawerSide.Right },
        React.createElement(Label, { caption: "Theme" },
            React.createElement(ThemeSelectorConnected, null)),
        React.createElement(Label, { caption: "Background" },
            React.createElement(BackgroundSelectorConnected, null)),
        React.createElement(Label, { caption: "Music" },
            React.createElement(MusicSelectorConnected, null)),
        React.createElement(Label, { caption: "Typing sounds" },
            React.createElement(TypingSoundsSelectorConnected, null)),
        React.createElement(Label, { caption: "Font" },
            React.createElement(FontSelectorConnected, null)),
        React.createElement(Label, { caption: "Paragraph spacing" },
            React.createElement(ParagraphSpacingConnected, null)),
        React.createElement(Label, { caption: "Line spacing" },
            React.createElement(LineSpacingConnected, null)),
        React.createElement(Label, { caption: "First line indent" },
            React.createElement(FirstLineIndentConnected, null)));
}
