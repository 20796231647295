import * as React from "react";
import * as ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";
import { DelayedRemove } from "ui/delayed-remove";
import { ThemeColor, ThemeContext, ThemeProvider, shadow } from "ui/theme";
export const DialogTitle = styled.div `
margin: 16px;
line-height: 32px;
font-size: 21px;
font-weight: 600;
`;
export const DialogBody = styled.div `
margin: 16px;
`;
export const DialogButtons = styled.div `
display: flex;
flex-direction: row;
justify-content: flex-end;
`;
const overlayKeyframes = keyframes `
from { opacity 0; }
to: { opacity: 1; }
`;
const dialogKeyframes = keyframes `
from { transform: translateY(-50px); }
to { transform: none; }
`;
const DialogOverlay = styled.div `
position: fixed;
z-index: 100;
left: 0;
right: 0;
top: 0;
bottom: 0;
background: ${ThemeColor.Overlay};
display: flex;
align-items: center;
justify-content: center;
opacity: ${({ isOpen }) => isOpen ? 1 : 0};
transition: opacity 0.5s;
animation: ${overlayKeyframes} 0.5s;
`;
const DialogContainer = styled.div `
min-width: 340px;
box-shadow: ${shadow(24)};
border-radius: 4px;
background: ${ThemeColor.PaperLight};
padding: 8px;
transform: ${({ isOpen }) => isOpen ? "none" : "translateY(-50px)"};
transition: transform 0.5s;
animation: ${dialogKeyframes} 0.5s;
`;
export function Dialog({ isOpen, onClose, children }) {
    const theme = React.useContext(ThemeContext), overlayClickHandler = React.useCallback((e) => {
        if (e.currentTarget === e.target) {
            onClose();
        }
    }, [onClose]), container = React.useMemo(() => document.createElement("div"), []);
    React.useEffect(() => {
        container.classList.add("dialog-portal");
        document.body.appendChild(container);
        return () => container.remove();
    }, []);
    return ReactDOM.createPortal(React.createElement(DelayedRemove, { visible: isOpen, delay: 500 },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(DialogOverlay, { isOpen: isOpen, onClick: overlayClickHandler },
                React.createElement(DialogContainer, { isOpen: isOpen }, children)))), container);
}
