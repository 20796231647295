import { createReduxBlock } from "redux-sacala";
import { schema } from "./schema";
const maxCharsPerLine = 60;
function nodeToString(node, out) {
    let paragraph = "";
    node.forEach(child => {
        if (child.type === schema.nodes.hard_break) {
            out.push(paragraph);
            paragraph = "";
        }
        else {
            paragraph += child.textContent;
        }
    });
    out.push(paragraph);
}
export function countWords(line) {
    var m = line.replace(/&.*;/g, "").match(/[^\s\x00-@]+/g);
    if (m) {
        var count = 0;
        for (var i = 0; i < m.length; i++) {
            count += m[i].charCodeAt(0) >= 0x4e00 ? m[i].length : (m[i].length > 1 ? 1 : 0);
        }
        return count;
    }
    else {
        return 0;
    }
}
export const { reducer: textCountersReducer, actions: textCounter, createMiddleware: createTextCounterMiddleware } = createReduxBlock()({
    name: "textCounters",
    initial: {
        chars: 0,
        nonSpaceChars: 0,
        words: 0,
        lines: 0
    },
    actions: {
        recalculate: (_, { title, body }) => {
            const lines = [title];
            body && body.doc.forEach(node => nodeToString(node, lines));
            return {
                chars: lines.reduce((r, v) => r + v.length, 0),
                nonSpaceChars: lines.reduce((r, v) => r + v.replace(/\s+/g, "").length, 0),
                words: Math.ceil(lines.reduce((r, line) => r + countWords(line), 0)),
                lines: lines.reduce((r, v) => r + Math.min(1, Math.ceil(v.length / maxCharsPerLine)), 0) - 1,
            };
        }
    }
});
