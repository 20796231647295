export var MenuItem;
(function (MenuItem) {
    MenuItem["None"] = "None";
    MenuItem["Settings"] = "Settings";
    MenuItem["Stats"] = "Stats";
    MenuItem["Export"] = "Export";
})(MenuItem || (MenuItem = {}));
export var DialogType;
(function (DialogType) {
    DialogType["None"] = "None";
    DialogType["SelectFont"] = "SelectFont";
})(DialogType || (DialogType = {}));
export var ActiveScreen;
(function (ActiveScreen) {
    ActiveScreen["Library"] = "Library";
    ActiveScreen["Document"] = "Document";
})(ActiveScreen || (ActiveScreen = {}));
export var FontStatus;
(function (FontStatus) {
    FontStatus["Initial"] = "Initial";
    FontStatus["Loading"] = "Loading";
    FontStatus["Ready"] = "Ready";
    FontStatus["Failed"] = "Failed";
})(FontStatus || (FontStatus = {}));
export var EntryType;
(function (EntryType) {
    EntryType["Document"] = "d";
    EntryType["Folder"] = "f";
})(EntryType || (EntryType = {}));
