import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { DelayedRemove } from "ui/delayed-remove";
import { ThemeColor, shadow, scrollable } from "ui/theme";
const backgroundKeyframes = keyframes `
from { background-color: transparent; }
to { background-color: rgba(0, 0, 0, 0.31); }
`;
const slideFromLeftKeyframes = keyframes `
from {
    box-shadow: ${shadow(0)};
    transform: translateX(-100%);
}
to {
    box-shadow: ${shadow(16)};
    transform: none;
}
`;
const slideFromRightKeyframes = keyframes `
from {
    box-shadow: ${shadow(0)};
    transform: translateX(100%);
}
to {
    box-shadow: ${shadow(16)};
    transform: none;
}`;
const Container = styled.div `
position: fixed;
z-index: 1;
left: 0;
top: 0;
right: 0;
bottom: 0;
overflow: hidden;
pointer-events: ${({ open }) => open ? "auto" : "none"};
background-color: ${({ open }) => open ? ThemeColor.Overlay : ThemeColor.Transparent};
transition: background-color ${({ open }) => open ? "0.3s" : "0.5s"};
animation: ${backgroundKeyframes} 0.3s;
`;
const Body = scrollable(styled.div `
position: absolute;
top: 0;
bottom: 0;
${({ side }) => side === DrawerSide.Left ? "left: 0" : "right: 0"};
width: 300px;
padding: 16px;
box-shadow: ${({ open }) => shadow(open ? 16 : 0)};
transform: ${({ open, side }) => {
    if (open) {
        return "none";
    }
    else if (side === DrawerSide.Left) {
        return "translateX(-100%)";
    }
    else {
        return "translateX(100%)";
    }
}};
transition: transform 0.3s, box-shadow 0.3s, background-color 0.5s;
background: ${ThemeColor.PaperLight};
animation: ${({ side }) => side === DrawerSide.Left ? slideFromLeftKeyframes : slideFromRightKeyframes} 0.3s;
`);
export var DrawerSide;
(function (DrawerSide) {
    DrawerSide["Left"] = "Left";
    DrawerSide["Right"] = "Right";
})(DrawerSide || (DrawerSide = {}));
export function Drawer({ open, onHide, children, side }) {
    const containerClickHandler = useCallback((e) => {
        if (e.target === e.currentTarget) {
            onHide();
        }
    }, [onHide]);
    return React.createElement(DelayedRemove, { visible: open, delay: 500 },
        React.createElement(Container, { open: open, onClick: containerClickHandler },
            React.createElement(Body, { open: open, side: side }, children)));
}
