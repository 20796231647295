var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function blobToBase64(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                const content = reader.result;
                resolve(content.replace(/^data:.+;base64,/, ''));
            };
            reader.readAsDataURL(blob);
        });
    });
}
export function delay(duration) {
    return new Promise(resolve => setTimeout(resolve, duration));
}
