var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import styled, { keyframes } from "styled-components";
import { eachFrame } from "lib";
import { ThemeColor } from "ui/theme";
const transitionDuration = 500;
const ImageNode = styled.img `
position: absolute;
left: 50%;
top: 50%;
min-width: 100%;
min-height: 100%;
transform: translate(-50%, -50%);
opacity: 0;
will-change: opacity;
`;
const containerKeyframes = keyframes `
from { opacity: 0 }
to { opacity: 1 }
`;
const ImageContainer = styled.div `
position: fixed;
left: 0;
top: 0;
right: 0;
bottom: 0;
overflow: hidden;
background: ${ThemeColor.PaperNormal};
transition: background-color 500ms;
animation: ${containerKeyframes} 0.3s;
`;
function easing(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}
class Image extends React.Component {
    constructor() {
        super(...arguments);
        this.node = null;
        this.visible = false;
        this.disposed = false;
        this.show = () => __awaiter(this, void 0, void 0, function* () {
            this.visible = true;
            const min = this.opacity;
            yield eachFrame(transitionDuration, position => {
                this.opacity = min + (1 - min) * easing(position);
            }, () => this.visible && !this.disposed);
            if (!this.disposed && this.visible && this.props.onShow) {
                this.props.onShow(this.props.id);
            }
        });
        this.hide = () => __awaiter(this, void 0, void 0, function* () {
            this.visible = false;
            const max = this.opacity;
            yield eachFrame(transitionDuration, position => {
                this.opacity = max * (1 - easing(position));
            }, () => !this.visible && !this.disposed);
            if (!this.disposed && !this.visible && this.props.onHide) {
                this.props.onHide(this.props.id);
            }
        });
        this.setNode = (node) => {
            this.node = node;
        };
    }
    render() {
        const { src } = this.props;
        return React.createElement(ImageNode, { ref: this.setNode, src: src, onLoad: this.show, draggable: false });
    }
    componentDidUpdate(prev) {
        const props = this.props;
        if (prev.visible != props.visible) {
            if (props.visible) {
                this.show();
            }
            else {
                this.hide();
            }
        }
    }
    componentWillUnmount() {
        this.disposed = true;
    }
    get opacity() {
        const opacity = this.props.opacity || 1;
        return parseFloat(this.node.style.opacity || "0") / opacity;
    }
    set opacity(value) {
        const opacity = this.props.opacity || 1;
        this.node.style.opacity = (value * opacity).toFixed(4);
    }
}
let counter = 0;
export class Background extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            image: null,
            items: []
        };
        this.onHide = (id) => {
            this.setState(({ items }) => ({
                items: items.filter(v => v.id !== id)
            }));
        };
    }
    render() {
        const { image: lastImage, items } = this.state;
        return React.createElement(ImageContainer, null, items.map(({ id, image }, i) => {
            return React.createElement(Image, { key: id, id: id, src: image, visible: i === items.length - 1 && image !== null, onHide: this.onHide, opacity: this.props.opacity });
        }));
    }
    static getDerivedStateFromProps({ image }, state) {
        if (image !== state.image) {
            return {
                image: image,
                items: image ? [...state.items, {
                        id: `image_${counter++}`,
                        image: image
                    }] : state.items
            };
        }
        else {
            return state;
        }
    }
}
