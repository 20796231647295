import * as React from "react";
import { IconButton } from "ui/button";
import { mdiDownload } from "@mdi/js";
import { Menu, MenuItem } from "ui/menu";
import styled from "styled-components";
const DownloadMenu = styled(Menu) `
position: absolute;
bottom: 0;
right: 0;
transform-origin: right bottom;
`;
export function DownloadButton({ className, onDownloadDocx, onDownloadHtml, onDownloadPdf, onDownloadTxt }) {
    const [isMenuOpen, setMenuState] = React.useState(false), openMenu = React.useCallback(() => setMenuState(true), [setMenuState]), closeMenu = React.useCallback(() => setMenuState(false), [setMenuState]);
    return React.createElement("div", { className: className },
        React.createElement(IconButton, { icon: mdiDownload, onClick: openMenu }),
        React.createElement(DownloadMenu, { onHide: closeMenu, open: isMenuOpen },
            React.createElement(MenuItem, { onClick: onDownloadPdf }, "Export to PDF"),
            React.createElement(MenuItem, { onClick: onDownloadTxt }, "Export to TXT"),
            React.createElement(MenuItem, { onClick: onDownloadHtml }, "Export to HTML"),
            React.createElement(MenuItem, { onClick: onDownloadDocx }, "Export to DOCX")));
}
