var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { selectEditorTitle } from "state";
import values from "lodash-es/values";
import flatMap from "lodash-es/flatMap";
import { loadFontFile } from "api";
import { blobToBase64 } from "utils";
import { schema, BlockType, BlockAlign } from "state/schema";
function loadPdfMake() {
    return import(/* webpackChunkName: "pdfmake" */ "pdfmake/build/pdfmake");
}
function createPdf(description, fonts) {
    return __awaiter(this, void 0, void 0, function* () {
        const pdfMake = yield loadPdfMake();
        const fileNames = flatMap(values(fonts), (v) => values(v)), files = (yield Promise.all(fileNames.map((fileName) => __awaiter(this, void 0, void 0, function* () {
            return {
                file: fileName,
                content: yield blobToBase64(yield loadFontFile(fileName))
            };
        })))).reduce((r, v) => {
            r[v.file] = v.content;
            return r;
        }, {});
        return pdfMake.createPdf(description, {}, fonts, files);
    });
}
var StyleName;
(function (StyleName) {
    StyleName["Header"] = "Header";
    StyleName["Chapter"] = "Chapter";
    StyleName["Paragraph"] = "Paragraph";
    StyleName["AlignLeft"] = "AlignLeft";
    StyleName["AlignRight"] = "AlignRight";
    StyleName["AlignCenter"] = "AlignCenter";
    StyleName["AlignJustify"] = "AlignJustify";
})(StyleName || (StyleName = {}));
const alignToStyleName = {
    [BlockAlign.Center]: StyleName.AlignCenter,
    [BlockAlign.Justify]: StyleName.AlignJustify,
    [BlockAlign.Left]: StyleName.AlignLeft,
    [BlockAlign.Right]: StyleName.AlignRight
};
function* nodeToPdfItems(node) {
    switch (node.type) {
        case schema.nodes.doc:
            for (let i = 0; i < node.childCount; i++) {
                yield* nodeToPdfItems(node.child(i));
            }
            break;
        case schema.nodes.paragraph:
            const children = [];
            node.forEach(node => children.push(...nodeToPdfItems(node)));
            yield {
                text: children,
                style: [
                    node.attrs.type === BlockType.Chapter ? StyleName.Chapter : StyleName.Paragraph,
                    alignToStyleName[node.attrs.align],
                ]
            };
            break;
        case schema.nodes.hard_break:
            yield "\n";
            break;
        case schema.nodes.text:
            const marks = node.marks.map(m => m.type);
            yield {
                text: node.textContent,
                italics: marks.includes(schema.marks.i),
                bold: marks.includes(schema.marks.b),
                decoration: marks.includes(schema.marks.u) ? "underline" : undefined
            };
            break;
        default:
            throw new Error(`Unexpected node type: '${node.type.name}'`);
    }
}
function* generatePdfItems(title, node) {
    if (title) {
        yield {
            text: title,
            style: [StyleName.Header]
        };
    }
    yield* nodeToPdfItems(node);
}
export function convertToPdf(state) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!state.editor) {
            throw new Error("Can not convert emty document");
        }
        const title = selectEditorTitle(state), { content } = state.editor, font = state.settings.fontFamily, { firstLineIndent, lineSpacing, paragraphSpacing } = state.settings, fontInfo = state.fonts.find(f => f.family === font), files = fontInfo.files, fontDescriptions = {
            normal: files.regular,
            bold: files.bold || files["700"] || files["800"] || files["600"] || files["900"] || files.regular,
            italics: files.italic || files.regular,
            bolditalics: files["700italic"] || files["800italic"] || files["600italic"] || files["900italic"] || files.italic || files.regular
        };
        return yield new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            (yield createPdf({
                content: Array.from(generatePdfItems(title, content.doc)),
                styles: {
                    [StyleName.Header]: {
                        fontSize: 24,
                        alignment: "center",
                        margin: [0, paragraphSpacing * 12, 0, paragraphSpacing * 12]
                    },
                    [StyleName.Chapter]: {
                        fontSize: 18,
                        margin: [0, paragraphSpacing * 9, 0, paragraphSpacing * 9]
                    },
                    [StyleName.Paragraph]: {
                        fontSize: 14,
                        margin: [0, paragraphSpacing * 7, 0, paragraphSpacing * 7],
                        preserveLeadingSpaces: true
                    },
                    [StyleName.AlignLeft]: { alignment: "left" },
                    [StyleName.AlignRight]: { alignment: "right" },
                    [StyleName.AlignCenter]: { alignment: "center" },
                    [StyleName.AlignJustify]: { alignment: "justify" }
                },
                defaultStyle: {
                    fontSize: 16,
                    font: font,
                    leadingIndent: firstLineIndent * 16,
                    lineHeight: lineSpacing
                },
            }, {
                [font]: fontDescriptions
            })).getBlob((blob) => resolve(blob));
        }));
    });
}
