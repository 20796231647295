import { connect } from "react-redux";
import { ProfileButton } from "ui/profile-button";
import { user, selectIsAnonimous } from "state";
export const LoginButtonConnected = connect((state) => ({
    isAnonimous: selectIsAnonimous(state)
}), dispatch => {
    return {
        onGoogleLogin: () => dispatch(user.loginWithGoogle()),
        onFacebookLogin: () => dispatch(user.loginWithFacebook()),
        onLogout: () => dispatch(user.logOut())
    };
})(ProfileButton);
