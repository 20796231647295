import * as React from "react";
import styled from "styled-components";
import { DropDownMenu } from "ui/drop-down";
import { MenuItem } from "ui/menu";
import { Slider } from "ui/slider";
const FontSizeSLider = styled(Slider) `
margin-left: 12px;
margin-right: 8px;
`;
function FontMenuItem({ font, selected, onSelect }) {
    const handleClick = React.useCallback(function () {
        onSelect(font);
    }, [font, onSelect]);
    return React.createElement(MenuItem, { onClick: handleClick, selected: selected }, font);
}
export const FontSelect = React.memo(({ font, size, fontList, onFontChange, onSizeChange, onOpenDialog, className }) => {
    const setSize = React.useCallback(size => onSizeChange(size), [onSizeChange]);
    return React.createElement(DropDownMenu, { className: className, label: `${font} (${size.toFixed(0)}px)` },
        React.createElement(FontSizeSLider, { min: 12, max: 32, value: size, onChange: setSize, isInteger: true }),
        fontList.map(item => {
            return React.createElement(FontMenuItem, { key: item, font: item, selected: item === font, onSelect: onFontChange });
        }),
        React.createElement(MenuItem, { onClick: onOpenDialog }, "All Fonts\u2026"));
});
