import * as React from "react";
import styled from "styled-components";
import { ThemeColor } from "ui/theme";
const TextInputContainer = styled.div `
position: relative;
display: flex;
flex-direction: column;
align-items: stretch;
padding: 8px 0;
::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: ${ThemeColor.Accent};
    transform: ${({ focus }) => focus ? "none" : "scaleX(0)"};
    opacity: ${({ focus, readOnly }) => focus && !readOnly ? "1" : "0"};
    transition: opacity 0.3s, transform 0.3s;
    bottom: 8px;
}
`;
const TextInputNode = styled.input `
display: block;
outline: none;
background: none;
padding: 8px 0 7px 0;
font-size: 16px;
border-style: solid;
border-width: 0 0 1px 0;
border-color: ${({ readOnly }) => readOnly ? ThemeColor.Transparent : ThemeColor.Border};
cursor: ${({ readOnly }) => readOnly ? "unset" : "text"};
transition: border-color 0.3s;
color: ${ThemeColor.PrimaryText};
width: 100%;
:focus {
    border-color: ${ThemeColor.Transparent};
}
::selection {
    ${({ readOnly }) => readOnly ? `background: ${ThemeColor.Transparent};` : ""};
}
`;
export const TextInput = React.memo(({ value, onChange, autofocus = false, placeholder, className, readonly = false, onFocus, onBlur, onEnter, onEscape, }) => {
    const [focus, setFocus] = React.useState(autofocus), focusHandler = React.useCallback(() => {
        setFocus(true);
        onFocus && onFocus();
    }, [onFocus]), blurHandler = React.useCallback(() => {
        setFocus(false);
        onBlur && onBlur();
    }, [onBlur]), onInput = React.useCallback((e) => onChange(e.currentTarget.value), [onChange]), inputNode = React.useRef(null), handleKeydown = React.useCallback((e) => {
        if (onEnter && e.keyCode === 13) {
            onEnter();
        }
        else if (onEscape && e.keyCode === 27) {
            onEscape();
        }
    }, [onEnter]);
    React.useEffect(() => {
        if (autofocus && inputNode.current) {
            inputNode.current.focus();
        }
    }, [autofocus, inputNode.current]);
    return React.createElement(TextInputContainer, { className: className, focus: focus, readOnly: readonly },
        React.createElement(TextInputNode, { ref: inputNode, value: value, onChange: onInput, placeholder: placeholder, autoFocus: autofocus, readOnly: readonly, onFocus: focusHandler, onBlur: blurHandler, onKeyDown: handleKeydown }));
});
