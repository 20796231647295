import noop from "lodash-es/noop";
const startListenersAction = "listeners/start";
export function startListeners() {
    return { type: startListenersAction };
}
export function createListenerMiddleware(selector, effect) {
    return api => {
        let started = false, cleanup = noop;
        return next => action => {
            if (!started && action.type === startListenersAction) {
                started = true;
                next(action);
                cleanup = effect(api, selector(api.getState())) || noop;
            }
            else if (started) {
                const previous = selector(api.getState());
                next(action);
                const current = selector(api.getState());
                if (previous !== current) {
                    cleanup();
                    cleanup = effect(api, current) || noop;
                }
            }
            else {
                next(action);
            }
        };
    };
}
