import * as React from "react";
import styled, { keyframes } from "styled-components";
import { DelayedRemove } from "ui/delayed-remove";
const appearKeyframes = keyframes `
from {
    transform: scale(1.1) translateY(-5vh);
    opacity: 0;
    pointer-events: none;
}
to {
    transform: none;
    opacity: 1;
    pointer-events: none;
}
`;
const ScreenNode = styled.div `
transform: ${({ active }) => active ? "none" : "scale(1.1) translateY(-5vh)"};
opacity: ${({ active }) => active ? 1 : 0};
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
z-index: ${({ active }) => active ? 1 : 0};
transition-duration: 0.3s;
transition-timing-function: ease-in-out;
transition-property: transform, opacity;
overflow: hidden;
pointer-events: ${({ active }) => active ? "auto" : "none"};
animation: ${appearKeyframes} 0.3s ease-in-out;
`;
ScreenNode.displayName = "ScreenNode";
export function Screen(props) {
    return React.createElement(DelayedRemove, { visible: props.active, delay: 300 },
        React.createElement(ScreenNode, Object.assign({}, props)));
}
export const ScreenList = styled.div `
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
`;
ScreenList.displayName = "ScreenList";
