import { createReduxBlock } from "redux-sacala";
import screenfull from "screenfull";
export const { reducer: fullscreenReducer, actions: fullscreen, createMiddleware: createFullscreenMiddleware } = createReduxBlock()({
    name: "fullscreen",
    initial: false,
    actions: {
        set: (_, newState) => newState
    },
    effects: dispatch => {
        const api = screenfull;
        return {
            watch() {
                dispatch(fullscreen.set(api.isFullscreen));
                api.on("change", () => {
                    dispatch(fullscreen.set(api.isFullscreen));
                });
            },
            enter() {
                api.request();
            },
            exit() {
                api.exit();
            }
        };
    }
});
