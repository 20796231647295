var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createReduxBlock } from "redux-sacala";
import { loadTrackList } from "api";
const INTERVAL = 1000;
function delay(interval) {
    return new Promise((resolve) => setTimeout(resolve, interval));
}
function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}
class TrackPlayer {
    constructor(file, maxVolume = 0.4) {
        this.file = file;
        this.maxVolume = maxVolume;
        this.stopped = false;
        this.inTransition = false;
        const node = this.node = new Audio();
        node.volume = 0;
        node.preload = "auto";
        node.loop = true;
        node.oncanplay = () => __awaiter(this, void 0, void 0, function* () {
            try {
                yield node.play();
                this.volumeUp();
            }
            catch (e) {
                console.log("Failed to start playing music.");
            }
        });
        node.src = file;
        document.body.appendChild(this.node);
    }
    setVolume(volume) {
        if (this.maxVolume !== volume) {
            if (this.inTransition) {
                this.maxVolume = volume;
            }
            else {
                this.maxVolume = volume;
                this.volumeUp();
            }
        }
    }
    play() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.node.currentTime === 0) {
                try {
                    yield this.node.play();
                    this.volumeUp();
                }
                catch (e) {
                    console.log("Failed to start playing music.");
                }
            }
        });
    }
    dispose() {
        return __awaiter(this, void 0, void 0, function* () {
            this.stopped = true;
            yield this.volumeDown();
            this.node.remove();
        });
    }
    volumeUp() {
        return __awaiter(this, void 0, void 0, function* () {
            this.inTransition = true;
            try {
                let startTime = Date.now(), startVolume = this.node.volume;
                while (!this.stopped && (Date.now() - startTime) < INTERVAL) {
                    this.node.volume = startVolume + (this.maxVolume - startVolume) * easeInOutQuad((Date.now() - startTime) / INTERVAL);
                    yield delay(50);
                }
                if (!this.stopped) {
                    this.node.volume = this.maxVolume;
                }
            }
            finally {
                this.inTransition = false;
            }
        });
    }
    volumeDown() {
        return __awaiter(this, void 0, void 0, function* () {
            this.inTransition = true;
            try {
                let start = Date.now(), volume = this.node.volume;
                while ((Date.now() - start) < INTERVAL) {
                    this.node.volume = volume * (1 - easeInOutQuad((Date.now() - start) / INTERVAL));
                    yield delay(50);
                }
                if (!this.stopped) {
                    this.node.volume = 0;
                }
            }
            finally {
                this.inTransition = false;
            }
        });
    }
}
export const { actions: music, reducer: musicReducer, createMiddleware: musicMiddleware } = createReduxBlock()({
    name: "music",
    initial: {
        tracks: []
    },
    actions: {
        receiveTracks(state, tracks) {
            return Object.assign(Object.assign({}, state), { tracks });
        }
    },
    effects: (dispatch, getState) => {
        let player = null;
        let maxVolume = 0.4;
        return {
            loadList() {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(music.receiveTracks(yield loadTrackList()));
                });
            },
            play(track) {
                if (track) {
                    const file = `resources/music/${track}.mp3`;
                    if (!player || file !== player.file) {
                        player && player.dispose();
                        player = track ? new TrackPlayer(`resources/music/${track}.mp3`, maxVolume) : null;
                    }
                    else {
                        player && player.play();
                    }
                }
                else {
                    player && player.dispose();
                    player = null;
                }
            },
            setVolume(volume) {
                maxVolume = volume;
                player && player.setVolume(volume);
            },
            stop() {
                this.play(null);
            }
        };
    }
});
