import * as React from "react";
import styled, { keyframes } from "styled-components";
import { DelayedRemove } from "ui/delayed-remove";
import { IconButton } from "ui/button";
import { mdiFullscreen, mdiFullscreenExit } from "@mdi/js";
const ItemsContainer = styled.div `position: relative;`;
const appearAnimation = keyframes `
from { opacity: 0; }
to { opacity: 1; }
`;
const ItemNode = styled.div `
animation: ${appearAnimation} 300ms;
transition: opacity 300ms;
pointer-events: ${({ active }) => active ? "auto" : "none"};
opacity: ${({ active }) => active ? "1" : "0"};
z-index: ${({ active }) => active ? "1" : "2"};
position: ${({ active }) => active ? "static" : "absolute"};
left: 0;
top: 0;
`;
function Item({ active, children }) {
    return React.createElement(DelayedRemove, { visible: active, delay: 300 },
        React.createElement(ItemNode, { active: active }, children));
}
export function FullscreenButton({ className, fullscreen, onEnter, onExit }) {
    return React.createElement(ItemsContainer, { className: className },
        React.createElement(Item, { active: !fullscreen },
            React.createElement(IconButton, { icon: mdiFullscreen, onClick: onEnter })),
        React.createElement(Item, { active: fullscreen },
            React.createElement(IconButton, { icon: mdiFullscreenExit, onClick: onExit })));
}
