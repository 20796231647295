var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createReduxBlock } from "redux-sacala";
import { FontStatus } from "./state";
import { loadFontList, loadFont } from "api";
function setStatus(state, status, predicate) {
    if (state.some(f => predicate(f) && f.status !== status)) {
        return state.map(f => {
            if (predicate(f)) {
                return Object.assign(Object.assign({}, f), { status });
            }
            else {
                return f;
            }
        });
    }
    else {
        return state;
    }
}
export const { actions: fonts, createMiddleware: fontsMiddleware, reducer: fontsReducer } = createReduxBlock()({
    name: "fonts",
    initial: [],
    actions: {
        setList(_, fonts) {
            return fonts;
        },
        startLoading(state, font) {
            return setStatus(state, FontStatus.Loading, f => f.family === font);
        },
        failLoading(state, font) {
            return setStatus(state, FontStatus.Failed, f => f.family === font && f.status !== FontStatus.Ready);
        },
        successLoading(state, font) {
            return setStatus(state, FontStatus.Ready, f => f.family === font);
        }
    },
    effects: (dispatch, getState) => {
        return {
            loadList() {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(fonts.setList(yield loadFontList()));
                });
            },
            loadFont(font) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!getState().fonts.some(f => f.family === font && [FontStatus.Ready, FontStatus.Loading].includes(f.status))) {
                        try {
                            dispatch(fonts.startLoading(font));
                            yield loadFont(font);
                            dispatch(fonts.successLoading(font));
                        }
                        catch (e) {
                            dispatch(fonts.failLoading(font));
                        }
                    }
                });
            }
        };
    }
});
