import { connect } from "react-redux";
import { FsEntryList } from "ui/fs-entry-list";
import { selectCurrentEntries, selectEditedEntryId, entries, EntryType, settings, selectCurrentEntry, selectCurrentEntryLevel, } from "state";
export const FsEntriesConnected = connect((state) => ({
    entries: selectCurrentEntries(state),
    linkToParent: selectCurrentEntry(state),
    editEntryId: selectEditedEntryId(state),
    level: selectCurrentEntryLevel(state),
}), (dispatch) => ({
    onEdit: (entry, edit) => {
        dispatch(entries.setEditedId(edit ? entry.id : null));
    },
    onRename: (entry, newName) => {
        dispatch(entries.update(Object.assign(Object.assign({}, entry), { name: newName })));
    },
    onDelete: (entry) => {
        dispatch(entries.remove(entry));
    },
    onClick: (entry) => {
        if (entry.type === EntryType.Folder) {
            dispatch(entries.handleEntrySelect(entry));
        }
        else if (entry.type === EntryType.Document) {
            dispatch(settings.setCurrentDocument(entry.id));
            dispatch(settings.switchToDocument());
        }
    },
    onMove: (itemId, destinationId) => {
        dispatch(entries.move({ itemId, destinationId }));
    },
    onCopy: (itemId, destinationId) => {
        dispatch(entries.copy({ itemId, destinationId }));
    }
}))(FsEntryList);
