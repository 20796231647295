export class EventStream {
    constructor() {
        this.listeners = new Set();
    }
    on(listener) {
        this.listeners.add(listener);
    }
    off(listener) {
        this.listeners.delete(listener);
    }
    trigger(event) {
        this.listeners.forEach(listener => listener(event));
    }
}
export var Collection;
(function (Collection) {
    function values(collection) {
        return Object.values(collection);
    }
    Collection.values = values;
    function create(items, key) {
        return items.reduce((r, v) => {
            r[key(v)] = v;
            return r;
        }, {});
    }
    Collection.create = create;
})(Collection || (Collection = {}));
