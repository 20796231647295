import { createReduxBlock } from "redux-sacala";
import { MenuItem } from "./state";
export const { actions: menu, createMiddleware: menuMiddleware, reducer: menuReducer } = createReduxBlock()({
    name: "menu",
    initial: MenuItem.None,
    actions: {
        openSettings() {
            return MenuItem.Settings;
        },
        openExport() {
            return MenuItem.Export;
        },
        openStats() {
            return MenuItem.Stats;
        },
        close() {
            return MenuItem.None;
        }
    }
});
