import * as React from "react";
import styled from "styled-components";
import { scrollIntoView } from "state";
import { TitleInput } from "ui/title-input";
import { EditorView } from "prosemirror-view";
const EditorWrapper = styled.div `
min-height: 100vh;
margin: 0 auto;
padding-top: 32px;
display: flex;
flex-direction: column;
align-items: stretch;
cursor: text;
`;
EditorWrapper.displayName = "EditorColumn";
const BodyWrapper = styled.div `
flex-grow: 1;
width: 100%;
white-space: pre-wrap;
position: relative;
`;
BodyWrapper.displayName = "EditorWrapper";
export function Editor({ fontFamily, fontSize, title, onTitleChange, state, onTransaction, paragraphSpacing, lineSpacing, firstLineIndent, onKeyDown }) {
    const node = React.useRef(null);
    const [view, setView] = React.useState(null);
    React.useEffect(() => {
        if (state) {
            const view = new EditorView(node.current, {
                attributes: { style: "outline: none;" },
                state,
                dispatchTransaction: onTransaction
            });
            setView(view);
            if (title) {
                view.focus();
                scrollIntoView(view.state, view.dispatch);
            }
            return () => {
                setView(null);
                view.destroy();
            };
        }
    }, [!!state]);
    React.useLayoutEffect(() => {
        view && state && view.updateState(state);
    }, [view, state]);
    const focus = React.useCallback((e) => {
        if (view && e.currentTarget === e.target) {
            view.focus();
        }
    }, [view]);
    const handleKeyDown = React.useCallback((e) => {
        onKeyDown(e.keyCode);
    }, [onKeyDown]);
    const handleEnterPress = React.useCallback(() => {
        view && view.focus();
    }, [view]);
    const size = (scale) => `${(scale * fontSize).toFixed()}px`;
    return React.createElement(EditorWrapper, { onKeyDown: handleKeyDown },
        React.createElement(TitleInput, { title: title || "", onChange: onTitleChange, placeholder: "Untitled", baseFontSize: fontSize, onPressEnter: handleEnterPress, autofocus: !title }),
        React.createElement(BodyWrapper, { ref: node, onClick: focus, style: { fontFamily, fontSize } },
            React.createElement("style", { scoped: true }, `
                h2 {
                    font-size: ${size(2)};
                    margin: ${size(3)} 0 ${size(1)} 0;
                    font-weight: normal;
                }
                p {
                    font-size: ${size(1)};
                    margin: ${size(paragraphSpacing)} 0;
                }
                h2, p {
                    text-indent: ${size(firstLineIndent)};
                    line-height: ${lineSpacing.toFixed(2)}em;
                }
            `)));
}
