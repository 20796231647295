import * as React from "react";
import styled from "styled-components";
import { Theme } from "state/theme";
export var ThemeColor;
(function (ThemeColor) {
    /**
     * Tool bar background
     */
    ThemeColor["PaperDark"] = "var(--paper-dark-color)";
    /**
     * Default component background
     */
    ThemeColor["PaperNormal"] = "var(--paper-normal-color)";
    /**
     * Label color
     */
    ThemeColor["Label"] = "var(--label-color)";
    /**
     * Card or popup background
     */
    ThemeColor["PaperLight"] = "var(--paper-light-color)";
    /**
     * Text color
     */
    ThemeColor["PrimaryText"] = "var(--primary-text-color)";
    /**
     * Captions color
     */
    ThemeColor["SecondaryText"] = "var(--secondary-text-color)";
    /**
     * Icon color
     */
    ThemeColor["IconColor"] = "var(--secondary-text-color)";
    /**
     * Disabled/hint text
     */
    ThemeColor["DisabledText"] = "var(--disabled-text-color)";
    /**
     * Border color
     */
    ThemeColor["Border"] = "var(--border-color)";
    /**
     * Important elements color
     */
    ThemeColor["Accent"] = "var(--accent-color)";
    /**
     * Danger color
     */
    ThemeColor["Danger"] = "var(--danger-color)";
    ThemeColor["Overlay"] = "rgba(0, 0, 0, 0.31)";
    ThemeColor["Transparent"] = "transparent";
})(ThemeColor || (ThemeColor = {}));
const ThemeProviderNode = styled.div `${({ value }) => value === Theme.Light ? `
--paper-dark-color: rgb(232, 232, 232);
--paper-normal-color: rgb(243, 243, 243);
--paper-light-color: rgb(255, 255, 255);
--primary-text-color: rgba(0, 0, 0, .87);
--secondary-text-color: rgba(0, 0, 0, .54);
--disabled-text-color: rgba(0, 0, 0, .38);
--border-color: rgba(0, 0, 0, .12);
--accent-color: rgb(-54, 137, 202);
--label-color: #fff;
--danger-color: #ff5252;
color: ${ThemeColor.PrimaryText};
font-family: BlinkMacSystemFont, Roboto, Arial, Helvetica, sans-serif;
` : `
--paper-dark-color: rgb(37, 37, 37);
--paper-normal-color: rgb(48, 48, 48);
--paper-light-color: rgb(63, 63, 63);
--primary-text-color: #fff;
--secondary-text-color: rgba(255, 255, 255, .70);
--disabled-text-color: rgba(255, 255, 255, .50);
--border-color: rgba(255, 255, 255, 0.12);
--accent-color: rgb(-54, 137, 202);
--label-color: rgba(0, 0, 0, .87);
--danger-color: #ff5252;
color: ${ThemeColor.PrimaryText};
font-family: BlinkMacSystemFont, Roboto, Arial, Helvetica, sans-serif;
`}`;
ThemeProviderNode.displayName = "ThemeProviderNode";
export const ThemeContext = React.createContext(Theme.Light);
export function ThemeProvider({ theme, children, className }) {
    return React.createElement(ThemeContext.Provider, { value: theme },
        React.createElement(ThemeProviderNode, { value: theme, className: className }, children));
}
export function shadow(depth) {
    if (depth < 0.5) {
        return `0 1px 3px 0 transparent, 0 1px 2px 0 transparent`;
    }
    else {
        const s = (depth - 1) / 23, 
        // first shadow parameters
        o1 = ((0.298039 - 0.117647) * s + 0.117647).toFixed(3), r11 = 18 * s + 1, r12 = 54 * s + 6, 
        // second shadow parameters
        o2 = ((0.219608 - 0.117647) * s + 0.117647).toFixed(3), r21 = 14 * s + 1, r22 = 16 * s + 4;
        return `0px ${r11.toFixed(2)}px ${r12.toFixed(2)}px 0 rgba(0, 0, 0, ${o1}), `
            + `0px ${r21.toFixed(2)}px ${r22.toFixed(2)}px 0 rgba(0, 0, 0, ${o2})`;
    }
}
export function scrollable(component) {
    return styled(component) `
overflow: auto;
scrollbar-width: thin;
::-webkit-scrollbar { width: 4px; height: 4px; }
::-webkit-scrollbar:hover { border-radius: 50px; }
::-webkit-scrollbar-button { width: 0px; height: 0px; }
::-webkit-scrollbar-thumb { background: rgba(100, 100, 100, 0.5); border-radius: 50px; }
::-webkit-scrollbar-thumb:hover { background: rgba(100, 100, 100, 0.8); }
::-webkit-scrollbar-thumb:active { background: rgba(100, 100, 100, 1); }
::-webkit-scrollbar-track { background: rgba(100, 100, 100, 0); }
::-webkit-scrollbar-track:hover { background: rgba(100, 100, 100, 0.2); }
::-webkit-scrollbar-track:active { background: rgba(100, 100, 100, 0.2); }
::-webkit-scrollbar-corner { background: transparent; }
`;
}
