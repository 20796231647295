var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled from "styled-components";
import { scrollable } from "ui/theme";
import { mobileWidth, maxWidth, sidePadding } from "state";
import { HideOnTypingConnected } from "connectors";
const mediaMobile = `screen and (max-width: ${mobileWidth}px)`;
export const Layout = scrollable(styled.div `
width: 100%;
height: 100%;
--corner-indent: 16px;
@media ${mediaMobile} { --corner-indent: 8px; }
`);
const BodyContent = styled.div `
padding: 0 ${sidePadding}px;
min-height: 100vh;
`;
const BodyWrapper = styled.div `
max-width: ${maxWidth}px;
margin: var(--corner-indent) auto;
`;
export function Body(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return React.createElement(BodyWrapper, Object.assign({}, props),
        React.createElement(BodyContent, null, children));
}
const ButtonsContainer = styled((props) => React.createElement(HideOnTypingConnected, Object.assign({}, props))) `
position: fixed;
display: flex;
flex-direction: column;
z-index: 1;
`;
export const LTButtons = styled(ButtonsContainer) `
position: fixed;
left: var(--corner-indent);
top: var(--corner-indent);
`;
export const RTButtons = styled(ButtonsContainer) `
position: fixed;
right: var(--corner-indent);
top: var(--corner-indent);
`;
export const LBButtons = styled(ButtonsContainer) `
position: fixed;
left: var(--corner-indent);
bottom: var(--corner-indent);
`;
export const RBButtons = styled(ButtonsContainer) `
position: fixed;
right: var(--corner-indent);
bottom: var(--corner-indent);
`;
