var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { nanoid } from "nanoid";
import stringify from "json-stable-stringify";
import { EventStream } from "./collection";
import { patch, diff, diffSize, zip, unzip } from "lib/worker";
import "firebase/storage";
import axios from "axios";
import { serverTime } from "api/time";
import noop from "lodash-es/noop";
import { createQueue } from "lib/queue";
export class DocumentStorage {
    constructor(app) {
        this.app = app;
        this.enqueue = createQueue();
        this.onExternalUpdate = new EventStream();
        this.bases = new Map();
        this.collection = null;
        this.unsubscribe = noop;
        this.userId = null;
    }
    snapshot(idList) {
        return __awaiter(this, void 0, void 0, function* () {
            const documents = yield Promise.all(idList.map((id) => __awaiter(this, void 0, void 0, function* () {
                return { id, document: yield this.load(id) };
            }), []));
            return documents.reduce((r, v) => {
                if (v.document) {
                    r.set(v.id, v.document);
                }
                return r;
            }, new Map());
        });
    }
    setUser(userId, snapshot = new Map()) {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            if (userId !== this.userId) {
                this.stopSubscription();
                this.userId = userId;
                this.bases = new Map();
                this.collection = this.app
                    .firestore()
                    .collection(`users/${userId}/diffs`);
                for (const [id, content] of snapshot.entries()) {
                    const text = stringify(content), baseId = yield this.saveBase(id, text);
                    this.bases.set(id, { id: baseId, content: text });
                    yield this.collection.doc(id).set({
                        d: id,
                        b: baseId,
                        f: null,
                        t: yield serverTime(),
                    });
                }
                this.startSubscription();
            }
        }));
    }
    load(documentId) {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            const diff = yield this.loadLastDiff(documentId);
            if (diff) {
                const base = yield this.loadBase(documentId, diff.b);
                this.bases.set(documentId, {
                    id: diff.b,
                    content: base,
                });
                return JSON.parse(patch(base, diff.f));
            }
            else {
                return null;
            }
        }));
    }
    loadLastDiff(documentId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.collection
                .where("d", "==", documentId)
                .orderBy("t", "desc")
                .limit(1)
                .get(), docs = response.docs;
            return docs.length ? docs[0].data() : null;
        });
    }
    loadBase(documentId, baseId) {
        return __awaiter(this, void 0, void 0, function* () {
            const base = this.bases.get(documentId);
            if (base && baseId === base.id) {
                return base.content;
            }
            else {
                const result = (yield axios.get(yield this.app.storage().ref(`${this.userId}/d/${documentId}/${baseId}`).getDownloadURL(), {
                    responseType: "arraybuffer",
                    transformResponse: r => r
                })).data;
                return yield unzip(new Uint8Array(result));
            }
        });
    }
    save(documentId, content, time) {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            const text = stringify(content), base = this.bases.get(documentId) || {
                id: yield this.saveBase(documentId, text),
                content: text
            }, delta = yield diff(base.content, text), item = {
                d: documentId,
                t: time,
                b: base.id,
                f: delta
            };
            if (diffSize(delta) < 2048) {
                yield this.collection.add(item);
            }
            else {
                const baseId = yield this.saveBase(documentId, text);
                this.bases.set(documentId, { id: baseId, content: text });
                yield this.collection.add({
                    b: baseId,
                    d: documentId,
                    t: time,
                    f: null
                });
            }
        }));
    }
    saveBase(documentId, content) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseId = nanoid(), data = yield zip(content);
            yield this.app.storage().ref(`${this.userId}/d/${documentId}/${baseId}`).put(data);
            return baseId;
        });
    }
    startSubscription() {
        const initTime = serverTime();
        this.unsubscribe();
        this.unsubscribe = this.collection.orderBy("t", "desc").limit(1).onSnapshot(snapshot => {
            if (!snapshot.metadata.hasPendingWrites) {
                this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
                    const minTime = yield initTime;
                    const data = snapshot.docChanges()
                        .filter(change => change.type === "added")
                        .map(change => change.doc.data())
                        .filter(diff => diff.t > minTime)
                        .reduce((r, v) => {
                        const current = r.get(v.d);
                        if (!current || (current && current.t < v.t)) {
                            r.set(v.d, v);
                        }
                        return r;
                    }, new Map());
                    return yield Promise.all(Array.from(data.values()).map((delta) => __awaiter(this, void 0, void 0, function* () {
                        const base = yield this.loadBase(delta.d, delta.b);
                        this.bases.set(delta.d, { id: delta.b, content: base });
                        this.onExternalUpdate.trigger({
                            id: delta.d,
                            document: JSON.parse(patch(base, delta.f)),
                            time: delta.t,
                        });
                    })));
                }));
            }
        });
    }
    stopSubscription() {
        this.unsubscribe();
        this.unsubscribe = noop;
    }
    dispose() {
        this.unsubscribe();
    }
}
