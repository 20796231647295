import * as React from "react";
import styled, { keyframes } from "styled-components";
import { ThemeColor } from "ui/theme";
import { DelayedRemove } from "ui/delayed-remove";
const LoadingContainer = styled.div `
height: 2px;
opacity: 0;
transition: opacity 0.6s;
overflow: hidden;
`;
const lineKeyframes = keyframes `
from { transform: translateX(-100%); }
to { transform: translateX(150%); }
`;
const LoadingLine = styled.div `
height: 2px;
width: 66%;
background: ${ThemeColor.Accent};
animation: ${lineKeyframes} 1.6s linear infinite;
opacity: 0.4;
`;
export function Loading({ loading, className }) {
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        if (loading) {
            const timeout = setTimeout(() => setReady(true), 300);
            return () => clearTimeout(timeout);
        }
        else {
            setReady(false);
        }
    }, [loading]);
    return React.createElement(DelayedRemove, { delay: 600, visible: loading && ready },
        React.createElement(LoadingContainer, { className: className, style: { opacity: loading && ready ? 1 : 0 } },
            React.createElement(LoadingLine, null)));
}
