import * as React from "react";
import styled from "styled-components";
import { Slider } from "ui/slider";
import { VolumeIcon } from "./VolumeIcon";
const Container = styled.div `
display: flex;
flex-direction: row;
align-items: center;
flex-grow: 1;
flex-shrink: 1;
`;
export function VolumeSlider({ volume, style, className, onChange }) {
    return React.createElement(Container, { className: className, style: style },
        React.createElement(VolumeIcon, { volume: volume }),
        React.createElement(Slider, { min: 0, max: 1, value: volume, onChange: onChange, style: { marginLeft: 8 } }));
}
