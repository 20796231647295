import { connect } from "react-redux";
import { nanoid } from "nanoid";
import { FsButtons } from "ui/fs-buttons";
import { entries, settings } from "state";
export const FsButtonsConnected = connect(() => ({}), dispatch => ({
    onCreateDocument: () => {
        const id = nanoid();
        dispatch(entries.createDocument(id));
        dispatch(settings.setCurrentDocument(id));
        dispatch(settings.switchToDocument());
    },
    onCreateFolder: () => {
        const id = nanoid();
        dispatch(entries.createFolder(id));
    }
}))(FsButtons);
