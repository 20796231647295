import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { schema, selectEditorTitle } from "state";
import { BlockType } from "state/schema";
function map(node, cb) {
    const r = [];
    node.forEach((node, _, i) => {
        r.push(cb(node, i));
    });
    return r;
}
function NodeView({ node }) {
    switch (node.type) {
        case schema.nodes.doc:
            return React.createElement(React.Fragment, null, map(node, (node, i) => React.createElement(NodeView, { key: i, node: node })));
        case schema.nodes.hard_break:
            return React.createElement("br", null);
        case schema.nodes.paragraph:
            if (node.attrs.type === BlockType.Chapter) {
                return React.createElement("h2", { style: { textAlign: node.attrs.align } }, map(node, (node, i) => React.createElement(NodeView, { key: i, node: node })));
            }
            else {
                return React.createElement("p", { style: { textAlign: node.attrs.align } }, map(node, (node, i) => React.createElement(NodeView, { key: i, node: node })));
            }
        case schema.nodes.text:
            return node.marks.reduce((r, v) => {
                switch (v.type.name) {
                    case "u":
                        return React.createElement("u", null, r);
                    case "i":
                        return React.createElement("i", null, r);
                    case "b":
                        return React.createElement("b", null, r);
                    default:
                        throw new Error(`Unknown mark type: '${v.type.name}'`);
                }
            }, React.createElement(React.Fragment, null, node.textContent));
        default:
            throw new Error(`Unknown node type: '${node.type.name}'`);
    }
}
export function convertToHtml(state) {
    if (!state.editor) {
        throw new Error("Can not convert emty document");
    }
    const title = selectEditorTitle(state), { content } = state.editor, { firstLineIndent, fontFamily, fontSize, lineSpacing, paragraphSpacing } = state.settings, size = (scale) => `${(fontSize * scale).toFixed(0)}px`;
    return new Blob(["<!doctype html>" + renderToStaticMarkup(React.createElement("html", null,
            React.createElement("head", null,
                React.createElement("meta", { httpEquiv: "Content-Type", content: "text/html; charset=utf-8" }),
                React.createElement("title", null, title || "Untitled"),
                React.createElement("link", { rel: "stylesheet", href: `https://fonts.googleapis.com/css?family=${encodeURIComponent(fontFamily + ":b,i,bi,r")}&display=swap` }),
                React.createElement("style", { type: "text/css", dangerouslySetInnerHTML: {
                        __html: `h1{text-align:center;font-size:${size(2)};font-weight: bold;}` +
                            `h2{font-size:${size(1.5)};font-weight:normal;}` +
                            `p{font-size:${size(1)};}` +
                            `h1,h2,p{line-height:${lineSpacing.toFixed(2)}em;margin:${paragraphSpacing.toFixed(2)}em 0;font-family:'${fontFamily}';}` +
                            `h2,p{text-indent:${size(firstLineIndent)};}`
                    } })),
            React.createElement("body", null,
                React.createElement("main", { style: { maxWidth: 860, margin: "0 auto" } },
                    title && React.createElement("h1", null, title),
                    React.createElement(NodeView, { node: content.doc })))))], {
        type: "text/html; charset=utf-8"
    });
}
