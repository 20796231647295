import { createReduxBlock } from "redux-sacala";
export const { actions: user, createMiddleware: userMiddleware, reducer: userReducer, } = createReduxBlock()({
    name: "user",
    initial: null,
    actions: {
        setUserInfo(_, info) {
            return info;
        }
    },
    effects(dispatch) {
        return {
            watch(_, database) {
                database.onUserChange.on(u => {
                    if (u) {
                        dispatch(user.setUserInfo({ id: u.uid, isAnonimous: u.isAnonymous }));
                    }
                    else {
                        dispatch(user.setUserInfo(null));
                    }
                });
            },
            loginWithGoogle(_, database) {
                database.signInWithGoogle();
            },
            loginWithFacebook(_, database) {
                database.signInWithFacebook();
            },
            logOut(_, database) {
                database.signOut();
            }
        };
    }
});
