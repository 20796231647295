var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { nextFrame } from "./next-frame";
export function eachFrame(duration, callback, predicate = () => true) {
    return __awaiter(this, void 0, void 0, function* () {
        const startTime = Date.now(), endTime = startTime + duration;
        yield Promise.resolve();
        let now = startTime;
        while (now < endTime && predicate()) {
            callback((now - startTime) / duration);
            yield nextFrame();
            now = Date.now();
        }
        predicate() && callback(1);
    });
}
