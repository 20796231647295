import { connect } from "react-redux";
import { DownloadButton } from "ui/download-button";
import { editor } from "state";
export const DownloadButtonConnected = connect(() => ({}), dispatch => ({
    onDownloadPdf: () => dispatch(editor.downloadAsPdf()),
    onDownloadTxt: () => dispatch(editor.downloadAsTxt()),
    onDownloadDocx: () => dispatch(editor.downloadAsDocx()),
    onDownloadHtml: () => dispatch(editor.downloadAsHtml()),
}))(DownloadButton);
DownloadButtonConnected.displayName = "DownloadButtonConnected";
