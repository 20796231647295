var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createReduxBlock } from "redux-sacala";
import { nanoid } from "nanoid";
import { EntryType } from "./state";
import { Collection } from "api/collections";
import { serverTime } from "api";
import { selectCurrentFolderId } from "./selectors";
import { settings } from "./settings";
export const { actions: entries, createMiddleware: entriesMiddleware, reducer: entriesReducer, } = createReduxBlock()({
    name: "entries",
    initial: {
        items: {},
        editedId: null,
    },
    actions: {
        updateAll(state, items) {
            return Object.assign(Object.assign({}, state), { items });
        },
        setEditedId(state, id) {
            return Object.assign(Object.assign({}, state), { editedId: id });
        }
    },
    effects: (dispatch, getState) => {
        return {
            watch(_, database) {
                let first = true;
                database.entries.onUpdate.on(items => {
                    dispatch(entries.updateAll(items));
                    if (first && Object.keys(items).length == 0) {
                        first = false;
                        const id = nanoid();
                        dispatch(entries.createDocument(id));
                        dispatch(settings.setCurrentDocument(id));
                        dispatch(settings.switchToDocument());
                    }
                    first = false;
                });
            },
            create(entry, database) {
                database.entries.create(entry);
            },
            update(entry, database) {
                database.entries.update(entry);
            },
            remove(entry, database) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (entry.type === EntryType.Folder) {
                        for (const child of Collection.values(getState().entries.items)) {
                            if (child.parent === entry.id) {
                                yield this.remove(child, database);
                            }
                        }
                    }
                    yield database.entries.remove(entry);
                });
            },
            move({ itemId, destinationId }, database) {
                const entries = getState().entries.items, item = entries[itemId];
                if (item && (destinationId === null || entries[destinationId])) {
                    database.entries.update(Object.assign(Object.assign({}, item), { parent: destinationId }));
                }
            },
            copy({ itemId, destinationId }, database) {
                return __awaiter(this, void 0, void 0, function* () {
                    const entries = getState().entries.items, item = entries[itemId];
                    if (item && (destinationId === null || entries[destinationId])) {
                        if (item.type === EntryType.Folder) {
                            const folderId = nanoid();
                            yield database.entries.create(Object.assign(Object.assign({}, item), { id: folderId, parent: destinationId }));
                            for (const entry of Collection.values(entries)) {
                                if (entry.parent === itemId) {
                                    yield this.copy({
                                        itemId: entry.id,
                                        destinationId: folderId
                                    }, database);
                                }
                            }
                        }
                        else {
                            const documentId = nanoid();
                            yield database.entries.create(Object.assign(Object.assign({}, item), { id: documentId, parent: destinationId, created: yield serverTime() }));
                            const content = yield database.documents.load(item.id);
                            if (content) {
                                database.documents.save(documentId, content, yield serverTime());
                            }
                        }
                    }
                });
            },
            createFolder(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState();
                    dispatch(entries.create({
                        id,
                        name: "",
                        parent: selectCurrentFolderId(state),
                        type: EntryType.Folder,
                        created: yield serverTime()
                    }));
                    dispatch(entries.setEditedId(id));
                });
            },
            createDocument(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const state = getState();
                    dispatch(entries.create({
                        id,
                        name: "",
                        parent: selectCurrentFolderId(state),
                        type: EntryType.Document,
                        created: yield serverTime()
                    }));
                });
            },
            handleEntrySelect(entry) {
                if (entry.type === EntryType.Folder) {
                    dispatch(settings.setCurrentFolder(entry.id === selectCurrentFolderId(getState())
                        ? entry.parent
                        : entry.id));
                }
            }
        };
    }
});
