var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import memoize from "lodash-es/memoize";
const getResources = memoize(() => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios.get("resources/resources.json");
    return response.data;
}));
export function loadBackgroundList() {
    return __awaiter(this, void 0, void 0, function* () {
        return (yield getResources()).backgrounds;
    });
}
export function loadBackground(id) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield axios.get(`resources/backgrounds/${id}.jpg`, {
            responseType: "arraybuffer"
        });
        return URL.createObjectURL(new Blob([response.data], { type: "image/jpeg" }));
    });
}
export function loadTrackList() {
    return __awaiter(this, void 0, void 0, function* () {
        return (yield getResources()).music;
    });
}
export function loadTypingSoundsList() {
    return __awaiter(this, void 0, void 0, function* () {
        return (yield getResources()).typingSounds;
    });
}
export function loadTypingSound(id, key) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield axios.get(`resources/typing-sounds/${id}/${key}.mp3`, {
            responseType: "arraybuffer"
        });
        return URL.createObjectURL(new Blob([response.data], { type: "audio/mpeg" }));
    });
}
export const loadFontList = memoize(() => __awaiter(void 0, void 0, void 0, function* () {
    return (yield axios.get("https://www.googleapis.com/webfonts/v1/webfonts", {
        params: { key: "AIzaSyAD6HGsTEF67x-hMxv85jH8H5IIdoCoXhI" }
    })).data.items;
}));
export const loadFontFile = memoize((fileName) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios.get(fileName.replace(/^http:/, "https:"), { responseType: "arraybuffer" });
    return new Blob([response.data], { type: "font/ttf" });
}));
export const loadFont = memoize((font) => __awaiter(void 0, void 0, void 0, function* () {
    const info = (yield loadFontList()).find(({ family }) => font === family);
    if (info) {
        const node = document.createElement("style");
        node.textContent = (yield Promise.all(info.variants.map((variant) => __awaiter(void 0, void 0, void 0, function* () {
            const hasWeight = /\d/.test(variant), weight = hasWeight ? variant.replace(/[^0-9]+/g, "") : "normal", style = variant.replace(/[0-9]+/g, "") || "regular", blob = yield loadFontFile(info.files[variant]), url = URL.createObjectURL(blob);
            return `@font-face { font-family: '${font}'; src: url('${url}') format('truetype'); font-weight: ${weight}; font-style: ${style}; }`;
        })))).join("\n");
        document.head.appendChild(node);
    }
    else {
        throw new Error(`Font family '${font}' not found`);
    }
}));
