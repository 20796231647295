var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Collection, EventStream, } from "./collection";
import { createQueue } from "lib/queue";
export class EntriesStorage {
    constructor(app) {
        this.app = app;
        this.onUpdate = new EventStream();
        this.enqueue = createQueue();
        this.entries = null;
        this.trash = null;
        this.unsubscribe = null;
        this.userId = null;
    }
    create(entry) {
        return this.enqueue(() => this.entries.doc(entry.id).set(entry));
    }
    update(entry) {
        return this.enqueue(() => this.entries.doc(entry.id).set(entry));
    }
    remove(entry) {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            yield this.trash.doc(entry.id).set(entry);
            yield this.entries.doc(entry.id).delete();
        }));
    }
    removeAll() {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all([
                this.emptyCollection(this.entries),
                this.emptyCollection(this.trash)
            ]);
        });
    }
    snapshot() {
        return this.enqueue(() => this.loadAll());
    }
    setUser(userId, snapshot = {}) {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
            if (this.userId === userId)
                return;
            this.stopSubscription();
            this.entries = null;
            this.trash = null;
            this.userId = null;
            if (userId) {
                this.userId = userId;
                this.entries = this.app.firestore().collection(`users/${userId}/entries`);
                this.trash = this.app.firestore().collection(`users/${userId}/entries.deleted`);
                yield this.saveAll(snapshot);
                this.startSubscription();
            }
        }));
    }
    dispose() {
        return this.setUser(null);
    }
    // private area
    startSubscription() {
        this.stopSubscription();
        if (this.entries) {
            this.unsubscribe = this.entries.onSnapshot(snapshot => {
                this.onUpdate.trigger(Collection.create(snapshot.docs.filter(d => d.exists).map(d => d.data()), e => e.id));
            });
        }
    }
    stopSubscription() {
        this.unsubscribe && this.unsubscribe();
        this.unsubscribe = null;
    }
    loadAll() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.entries) {
                const response = yield this.entries.get();
                return response.docs.map(d => d.data()).reduce((r, v) => {
                    r[v.id] = v;
                    return r;
                }, {});
            }
            else {
                return {};
            }
        });
    }
    saveAll(entries) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const entry of Object.values(entries)) {
                this.entries && (yield this.entries.doc(entry.id).set(entry));
            }
        });
    }
    emptyCollection(collection) {
        return __awaiter(this, void 0, void 0, function* () {
            if (collection) {
                for (const doc of (yield collection.get()).docs) {
                    collection.doc(doc.id).delete();
                }
            }
        });
    }
}
