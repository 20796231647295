import * as React from "react";
import styled from "styled-components";
import { FlatButton } from "ui/button";
import Icon from "@mdi/react";
import { ThemeColor } from "ui/theme";
import { mdiFile, mdiFolder } from "@mdi/js";
const FsButton = styled(FlatButton) `
margin-left: 8px;
@media screen and (max-width: ${600}px) {
    margin-left: 0px;
    justify-content: flex-start;
    flex-direction: row-reverse;
}
`;
const ButtonsContainer = styled.div `
display: flex;
justify-content: flex-end;
`;
const Buttons = styled.div `
display: flex;
@media screen and (max-width: ${600}px) {
    flex-direction: column;
    align-items: stretch;
    margin-right: 8px;
}
`;
const ButtonIcon = styled(Icon) `
margin-right: 8px;
flex-shrink: 0;
@media screen and (max-width: ${600}px) {
    margin-left: 8px;
    margin-right: 0;
}
`;
export function FsButtons({ onCreateDocument, onCreateFolder }) {
    return React.createElement(ButtonsContainer, null,
        React.createElement(Buttons, null,
            React.createElement(FsButton, { onClick: onCreateDocument },
                React.createElement(ButtonIcon, { color: ThemeColor.IconColor, size: "24px", path: mdiFile }),
                "New document"),
            React.createElement(FsButton, { onClick: onCreateFolder },
                React.createElement(ButtonIcon, { color: ThemeColor.IconColor, size: "24px", path: mdiFolder }),
                "New folder")));
}
