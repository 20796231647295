import * as React from "react";
import styled from "styled-components";
import { mdiPollBox } from "@mdi/js";
import { IconButton, BaseButton, RippleOrigin } from "../button";
import { Menu } from "../menu";
import { ThemeColor } from "ui/theme";
const CountersList = styled(Menu) `
position: absolute;
transform-origin: right bottom;
right: 0;
bottom: 0;
z-index: 1;
`;
const Wrapper = styled.div `
position: relative;
`;
const Content = styled(BaseButton) ``;
const Row = styled.div `
display: flex;
justify-content: space-between;
padding: 8px 16px;
transition: background-color 0.3s;
&:hover { background-color: ${ThemeColor.Border}; }
&:first-child { padding-top: 16px; }
&:last-child { padding-bottom: 16px; }
`;
const Name = styled.div ``;
const Value = styled.div `
margin-left: 24px;
`;
export function CountersButton({ chars, nonSpaceChars, words, lines, pages, timeToRead }) {
    const [open, setOpen] = React.useState(false), openList = React.useCallback(() => setOpen(true), [setOpen]), hideList = React.useCallback(() => setOpen(false), [setOpen]);
    return React.createElement(Wrapper, null,
        React.createElement(IconButton, { icon: mdiPollBox, onClick: openList }),
        React.createElement(CountersList, { open: open, onHide: hideList },
            React.createElement(Content, { onClick: hideList, rippleOrigin: RippleOrigin.MOUSE, rippleColor: ThemeColor.Border },
                React.createElement("div", null,
                    React.createElement(Row, null,
                        React.createElement(Name, null, "Characters"),
                        React.createElement(Value, null, chars)),
                    React.createElement(Row, null,
                        React.createElement(Name, null, "Without spaces"),
                        React.createElement(Value, null, nonSpaceChars)),
                    React.createElement(Row, null,
                        React.createElement(Name, null, "Words"),
                        React.createElement(Value, null, words)),
                    React.createElement(Row, null,
                        React.createElement(Name, null, "Lines"),
                        React.createElement(Value, null, lines)),
                    React.createElement(Row, null,
                        React.createElement(Name, null, "Pages"),
                        React.createElement(Value, null, pages)),
                    React.createElement(Row, null,
                        React.createElement(Name, null, "Reading time"),
                        React.createElement(Value, null, timeToRead))))));
}
