import * as React from "react";
import styled from "styled-components";
import { FlatButton } from "ui/button";
import { ThemeProvider, ThemeColor } from "ui/theme/Theme";
import { Theme } from "state";
const StyledProvider = styled(ThemeProvider) `
flex-grow: 1;
flex-shrink: 1;
:first-child {
    margin-right: 2px;
}
:last-child {
    margin-left: 2px;
}`;
const ThemeButton = styled(FlatButton) `
background-color: ${ThemeColor.PaperNormal};
:hover {
    background-color: ${ThemeColor.PaperNormal};
    filter: invert(10%);
}`;
const ThemeSelectorWrapper = styled.div `
display: flex;
flex-direction: row;
margin-top: 10px;
`;
export function ThemeSelector({ onClick }) {
    const selectDay = React.useCallback(() => onClick(Theme.Light), [onClick]), selectNight = React.useCallback(() => onClick(Theme.Dark), [onClick]);
    return React.createElement(ThemeSelectorWrapper, null,
        React.createElement(StyledProvider, { theme: Theme.Light },
            React.createElement(ThemeButton, { onClick: selectDay }, "Day")),
        React.createElement(StyledProvider, { theme: Theme.Dark },
            React.createElement(ThemeButton, { onClick: selectNight }, "Night")));
}
