var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createReduxBlock } from "redux-sacala";
import { ActiveScreen } from "./state";
import { createListenerMiddleware, startListeners } from "api";
import { backgrounds } from "./backgrounds";
import { selectCurrentBackgroundId, selectCurrentTrack, selectMusicVolume, selectCurrentTypingSounds, selectFontFamily } from "./selectors";
import { fonts } from "./fonts";
import { music } from "./music";
import debounce from "lodash-es/debounce";
import { typingSounds } from "./typing-sounds";
import { Theme } from "./theme";
import { editor } from "./editor";
const version = "1";
const initial = {
    version,
    theme: Theme.Light,
    activeScreen: ActiveScreen.Document,
    background: "ecosse",
    music: "forests-of-rain",
    typingSounds: "old-typewriter",
    typingSoundsVolume: 0.4,
    musicVolume: 0.4,
    fontFamily: "Alegreya",
    fontSize: 16,
    paragraphSpacing: 1,
    lineSpacing: 1.5,
    firstLineIndent: 0,
    currentFolderId: null,
    currentDocumentId: null,
    saved: true,
};
export const { actions: settings, reducer: settingsReducer, createMiddleware: createSettingsMiddleware, } = createReduxBlock()({
    name: "settings",
    initial: initial,
    actions: {
        parse(state, settings) {
            return Object.assign(Object.assign(Object.assign({}, state), settings), { saved: true });
        },
        receive(state, _a) {
            var { currentFolderId, currentDocumentId, activeScreen } = _a, others = __rest(_a, ["currentFolderId", "currentDocumentId", "activeScreen"]);
            return Object.assign(Object.assign(Object.assign({}, state), others), { saved: true });
        },
        setTheme: (state, theme) => (Object.assign(Object.assign({}, state), { theme, saved: false })),
        setBackground: (state, background) => (Object.assign(Object.assign({}, state), { background, saved: false })),
        setFontFamily: (state, fontFamily) => (Object.assign(Object.assign({}, state), { fontFamily, saved: false })),
        setFontSize: (state, fontSize) => (Object.assign(Object.assign({}, state), { fontSize, saved: false })),
        setParagraphSpacing: (state, spacing) => (Object.assign(Object.assign({}, state), { paragraphSpacing: spacing, saved: false })),
        setLineSpacing: (state, spacing) => (Object.assign(Object.assign({}, state), { lineSpacing: spacing, saved: false })),
        setFirstLineIndent: (state, indent) => (Object.assign(Object.assign({}, state), { firstLineIndent: indent, saved: false })),
        setMusic: (state, music) => (Object.assign(Object.assign({}, state), { music, saved: false })),
        setMusicVolume: (state, musicVolume) => (Object.assign(Object.assign({}, state), { musicVolume, saved: false })),
        setTypingSounds: (state, typingSounds) => (Object.assign(Object.assign({}, state), { typingSounds, saved: false })),
        setTypingSoundsVolume: (state, typingSoundsVolume) => (Object.assign(Object.assign({}, state), { typingSoundsVolume, saved: false })),
        setCurrentFolder: (state, currentFolderId) => (Object.assign(Object.assign({}, state), { currentFolderId, saved: false })),
        setCurrentDocument: (state, currentDocumentId) => (Object.assign(Object.assign({}, state), { currentDocumentId, saved: false })),
        switchToDocument: state => (Object.assign(Object.assign({}, state), { activeScreen: ActiveScreen.Document, saved: false })),
        switchToLibrary: state => (Object.assign(Object.assign({}, state), { activeScreen: ActiveScreen.Library, saved: false })),
        markSaved: state => (Object.assign(Object.assign({}, state), { saved: true }))
    },
    effects: (dispatch, getState) => ({
        load: (_, database) => __awaiter(void 0, void 0, void 0, function* () {
            const data = yield database.settings.load();
            if (data) {
                dispatch(settings.parse(data));
            }
            dispatch(startListeners());
        }),
        watch: (_, database) => __awaiter(void 0, void 0, void 0, function* () {
            database.settings.onUpdate.on(updated => {
                dispatch(settings.receive(updated || initial));
            });
        }),
        save: (_, database) => __awaiter(void 0, void 0, void 0, function* () {
            const value = getState().settings;
            if (!value.saved) {
                database.settings.save(value);
                dispatch(settings.markSaved());
            }
        })
    }),
});
export const saveSettingsMiddleware = (api) => (next) => {
    const save = debounce(() => {
        api.dispatch(settings.save());
    }, 500);
    return (action) => {
        const initial = api.getState().settings;
        next(action);
        const current = api.getState().settings;
        if (initial !== current) {
            save();
        }
    };
};
export const loadFontMiddleware = createListenerMiddleware(selectFontFamily, ({ dispatch }, font) => {
    dispatch(fonts.loadFont(font));
});
export const musicVolumeMiddleware = createListenerMiddleware(selectMusicVolume, ({ dispatch }, volume) => {
    dispatch(music.setVolume(volume));
});
export const currentTrackMiddleware = createListenerMiddleware(selectCurrentTrack, ({ dispatch }, track) => dispatch(music.play(track)));
export const loadTypingSoundsMiddleware = createListenerMiddleware(selectCurrentTypingSounds, ({ dispatch }, sounds) => {
    sounds && dispatch(typingSounds.loadFiles(sounds));
});
export const loadBackgroundMiddleware = createListenerMiddleware(selectCurrentBackgroundId, ({ dispatch }, background) => {
    background && dispatch(backgrounds.load(background));
});
export const openDocumentMiddleware = createListenerMiddleware((state) => state.settings.currentDocumentId, ({ dispatch }, document) => document && dispatch(editor.open(document)));
