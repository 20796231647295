import { createReduxBlock } from "redux-sacala";
import throttle from "lodash-es/throttle";
export const { actions: typing, createMiddleware: createTypingMiddleware, reducer: typingReducer } = createReduxBlock()({
    name: "typing",
    initial: false,
    actions: {
        enable() {
            return true;
        },
        disable() {
            return false;
        }
    },
    effects: (dispatch, getState) => {
        let position = { x: 0, y: 0 };
        return {
            watch() {
                let delayedMoveHandler = throttle((e) => {
                    let { x: oldX, y: oldY } = position, { clientX: newX, clientY: newY } = e, dx = newX - oldX, dy = newY - oldY;
                    if ((dx * dx + dy * dy) > 64 && getState().typing) {
                        dispatch(typing.disable());
                    }
                    position = { x: newX, y: newY };
                }, 300, { leading: false, trailing: true });
                window.addEventListener("mousemove", delayedMoveHandler);
            }
        };
    }
});
