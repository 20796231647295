import visibilityjs from "visibilityjs";
import { createReduxBlock } from "redux-sacala";
export const { actions: visibility, reducer: visibilityReducer, createMiddleware: visibilityMiddleware, } = createReduxBlock()({
    name: "isVisible",
    initial: true,
    actions: {
        setVisibility(_, visible) {
            return visible;
        }
    },
    effects: dispatch => {
        return {
            watch() {
                dispatch(visibility.setVisibility(!visibilityjs.hidden()));
                visibilityjs.change(() => {
                    dispatch(visibility.setVisibility(!visibilityjs.hidden()));
                });
            }
        };
    }
});
