var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Collection, EventStream, EntriesStorage, SettingsStorage } from "./collections";
import { EntryType } from "state";
var auth = firebase.auth;
import { delay } from "lib";
import { DocumentStorage } from "./collections/documents";
import { createQueue } from "lib/queue";
export class ZenDatabase {
    constructor(app) {
        this.app = app;
        this.onUserChange = new EventStream();
        this.enqueue = createQueue();
        // Dispose
        this.unwatch = () => { };
        this.entries = new EntriesStorage(this.app);
        this.documents = new DocumentStorage(this.app);
        this.settings = new SettingsStorage(this.app);
    }
    start() {
        return __awaiter(this, void 0, void 0, function* () {
            const app = this.app, auth = app.auth();
            const waitForLogin = localStorage.getItem("waitForLogin") === "1";
            for (let i = 0; waitForLogin && i < 50 && !auth.currentUser; i++) {
                yield delay(100);
            }
            localStorage.setItem("waitForLogin", "1");
            if (auth.currentUser) {
                const userId = auth.currentUser.uid;
                yield Promise.all([
                    this.entries.setUser(userId),
                    this.documents.setUser(userId),
                    this.settings.setUser(userId)
                ]);
                yield this.refresh();
            }
            else {
                yield auth.signInAnonymously();
                const userId = auth.currentUser.uid;
                yield Promise.all([
                    this.entries.setUser(userId),
                    this.documents.setUser(userId),
                    this.settings.setUser(userId)
                ]);
            }
            this.unwatch = auth.onAuthStateChanged(user => {
                if (user) {
                    this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
                        yield Promise.all([
                            this.entries.setUser(user.uid),
                            this.documents.setUser(user.uid),
                            this.settings.setUser(user.uid)
                        ]);
                        this.onUserChange.trigger(user);
                    }));
                }
            });
        });
    }
    signInWithProvider(provider) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
                const auth = this.app.auth();
                if (auth.currentUser && auth.currentUser.isAnonymous && !provider) {
                    yield this.refresh();
                }
                else if (auth.currentUser && auth.currentUser.isAnonymous && provider) {
                    // Move anonimous data to account
                    const entries = yield this.entries.snapshot(), documents = yield this.documents.snapshot(Collection.values(entries)
                        .filter(e => e.type === EntryType.Document)
                        .map(e => e.id)), settings = yield this.settings.snapshot();
                    yield auth.signInWithPopup(provider);
                    const user = auth.currentUser;
                    this.onUserChange.trigger(auth.currentUser);
                    yield this.entries.setUser(user.uid, entries);
                    yield this.documents.setUser(user.uid, documents);
                    yield this.settings.setUser(user.uid, settings);
                }
                else {
                    // Just sign in
                    if (auth.currentUser && !(auth.currentUser.isAnonymous && provider === null)) {
                        yield auth.signOut();
                    }
                    if (provider) {
                        yield auth.signInWithPopup(provider);
                    }
                    else {
                        yield auth.signInAnonymously();
                    }
                    this.onUserChange.trigger(auth.currentUser);
                    yield this.entries.setUser(auth.currentUser.uid, {});
                    yield this.documents.setUser(auth.currentUser.uid);
                }
            }));
        });
    }
    signInAnonimously() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.signInWithProvider(null);
        });
    }
    signInWithGoogle() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.signInWithProvider(new auth.GoogleAuthProvider());
        });
    }
    signInWithFacebook() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.signInWithProvider(new auth.FacebookAuthProvider());
        });
    }
    signOut() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.enqueue(() => __awaiter(this, void 0, void 0, function* () {
                const auth = this.app.auth();
                yield auth.signOut();
                yield auth.signInAnonymously();
            }));
        });
    }
    getCurrentUser() {
        return this.enqueue(() => __awaiter(this, void 0, void 0, function* () { return this.app.auth().currentUser; }));
    }
    dispose() {
        return __awaiter(this, void 0, void 0, function* () {
            this.unwatch();
            yield this.entries.dispose();
        });
    }
    // Update all the collections and user
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            const auth = this.app.auth();
            this.onUserChange.trigger(auth.currentUser);
        });
    }
}
