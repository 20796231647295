const worker = new Worker("worker.js");
let counter = 0;
function callWorker(fn, params) {
    let id = "zenWorker_" + (counter++).toFixed();
    return new Promise((resolve, reject) => {
        const responseHandler = ({ data }) => {
            if (data && data.id === id) {
                if (data.type === "result") {
                    resolve(data.result);
                }
                else {
                    reject(`Worker request to '${fn}' failed`);
                }
                worker.removeEventListener("message", responseHandler);
            }
        };
        worker.addEventListener("message", responseHandler);
        worker.postMessage({ id, fn, params });
    });
}
export function zip(data) {
    return callWorker("zip", [data]);
}
export function unzip(data) {
    return callWorker("unzip", [data]);
}
export function diff(text1, text2) {
    return callWorker("diff", [text1, text2]);
}
export function diffSize(diff) {
    return diff ? diff.reduce((r, v) => {
        if (typeof v === "string") {
            return r + v.length + 2;
        }
        else {
            return r + 3;
        }
    }, 0) : 0;
}
export function patch(text, diff) {
    if (diff) {
        let output = "", position = 0;
        for (const d of diff) {
            if (typeof d === "string") {
                output += d;
            }
            else if (d <= 0) {
                position -= d;
            }
            else {
                output += text.substring(position, position + d);
                position += d;
            }
        }
        return output;
    }
    else {
        return text;
    }
}
