import * as React from "react";
import { connect } from "react-redux";
import { selectTheme } from "state";
import { ThemeProvider } from "ui/theme";
export const ThemeConnected = connect((state) => {
    return { value: selectTheme(state) };
})(({ value, className, children }) => {
    return React.createElement(ThemeProvider, { theme: value, className: className }, children);
});
ThemeConnected.displayName = "ThemeConnected";
