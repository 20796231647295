import { connect } from "react-redux";
import { BackgroundSelector } from "ui/background-selector";
import { selectCurrentBackgroundId, selectBackgroundsList, settings, backgrounds, selectBackgroundLoading, } from "state";
export const BackgroundSelectorConnected = connect((state) => ({
    value: selectCurrentBackgroundId(state),
    items: selectBackgroundsList(state),
    loading: selectBackgroundLoading(state)
}), dispatch => ({
    onSelect: (id) => {
        dispatch(backgrounds.load(id));
        dispatch(settings.setBackground(id));
    }
}))(BackgroundSelector);
