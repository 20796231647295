import { createReduxBlock } from "redux-sacala";
import { typing } from "./typing";
export const { actions: screen, reducer: screenReducer, createMiddleware: createScreenMiddleware, } = createReduxBlock()({
    name: "screen",
    initial: { width: 0, height: 0 },
    actions: {
        update: (_, state) => state
    },
    effects: (dispatch, getState) => {
        return {
            watch() {
                function update() {
                    const { width: oldWidth, height: oldHeight } = getState().screen, { innerWidth: width, innerHeight: height } = window;
                    if (oldWidth !== width || oldHeight !== height) {
                        dispatch(screen.update({
                            width: window.innerWidth,
                            height: window.innerHeight
                        }));
                        dispatch(typing.disable());
                    }
                }
                function updateTwice() {
                    update();
                    setTimeout(update, 300);
                }
                update();
                window.addEventListener("resize", updateTwice);
                window.addEventListener("orientationchange", updateTwice);
            }
        };
    }
});
