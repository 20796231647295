var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { schema, BlockType, BlockAlign } from "state/schema";
import { selectEditorTitle } from "state/selectors";
export function convertToDocx(state) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!state.editor) {
            throw new Error("Can not convert emty document");
        }
        const { Document, Paragraph, Packer, Run, TextRun } = yield import(/* webpackChunkName: "docx" */ "docx");
        function appendText(paragraph, node) {
            if (node.type.name === schema.nodes.text.name) {
                paragraph.addRun(node.marks.reduce((r, v) => {
                    switch (v.type.name) {
                        case "i":
                            return r.italics();
                        case "b":
                            return r.bold();
                        case "u":
                            return r.underline();
                        default:
                            throw new Error(`Unknown mark: '${v.type.name}'`);
                    }
                }, new TextRun(node.textContent)));
            }
            else if (node.type.name === schema.nodes.hard_break.name) {
                paragraph.addRun(new Run().break());
            }
            else {
                throw new Error(`Unknown node type: '${node.type.name}'`);
            }
        }
        function appendParagraph(document, node) {
            const paragraph = new Paragraph();
            if (node.attrs.type === BlockType.Chapter) {
                paragraph.heading2();
            }
            else {
                paragraph.style("Paragraph");
            }
            switch (node.attrs.align) {
                case BlockAlign.Left:
                    paragraph.left();
                    break;
                case BlockAlign.Right:
                    paragraph.right();
                    break;
                case BlockAlign.Center:
                    paragraph.center();
                    break;
                case BlockAlign.Justify:
                    paragraph.justified();
                    break;
            }
            node.forEach(node => {
                appendText(paragraph, node);
            });
            document.addParagraph(paragraph);
        }
        const title = selectEditorTitle(state), { content: { doc: node } } = state.editor, { lineSpacing, paragraphSpacing, firstLineIndent } = state.settings;
        const document = new Document();
        document.Styles
            .createParagraphStyle("Paragraph", "Paragraph")
            .basedOn("Normal")
            .size(28)
            .spacing({
            before: 14 * paragraphSpacing * 12,
            after: 14 * paragraphSpacing * 12,
            line: lineSpacing * 28 * 10
        });
        document.Styles
            .createParagraphStyle("Heading1", "Heading 1")
            .basedOn("Normal")
            .next("Paragraph")
            .center()
            .size(48)
            .spacing({
            before: 24 * paragraphSpacing * 12,
            after: 24 * paragraphSpacing * 12,
        });
        document.Styles
            .createParagraphStyle('Heading2', 'Heading 2')
            .basedOn("Normal")
            .next("Paragraph")
            .size(36)
            .spacing({
            before: 18 * paragraphSpacing * 12,
            after: 18 * paragraphSpacing * 12,
            line: lineSpacing * 36 * 10
        });
        title && document.createParagraph(title).heading1();
        node.forEach(node => {
            appendParagraph(document, node);
        });
        return yield (new Packer().toBlob(document));
    });
}
