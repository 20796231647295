import * as React from "react";
import { Dialog, DialogTitle, DialogBody, DialogButtons } from "ui/dialog";
import { FlatButton } from "ui/button";
import { FontList } from "ui/font-list";
import { TextInput } from "ui/text-input";
export function FontsDialog({ isOpen, onPreloadRequest, fonts, onSelect, onClose }) {
    const [searchString, setSearchString] = React.useState("");
    const [tokens, setTokens] = React.useState([]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setTokens(searchString.split(/\s+/g).filter(v => v !== "").map(v => v.toLowerCase()));
        }, 150);
        return () => clearTimeout(timeout);
    }, [searchString]);
    const filtered = React.useMemo(() => {
        return fonts.filter(({ family }) => {
            return tokens.every(t => family.toLowerCase().indexOf(t) !== -1);
        });
    }, [fonts, tokens]);
    return React.createElement(Dialog, { isOpen: isOpen, onClose: onClose },
        React.createElement(DialogTitle, null, "Choose a font"),
        React.createElement(DialogBody, { style: { minWidth: "60vw", maxWidth: "600px" } },
            React.createElement(TextInput, { placeholder: "Search", value: searchString, onChange: setSearchString, autofocus: true }),
            React.createElement(FontList, { items: filtered, onClick: onSelect, onPreloadRequest: onPreloadRequest, height: 480 })),
        React.createElement(DialogButtons, null,
            React.createElement(FlatButton, { onClick: onClose }, "Close")));
}
