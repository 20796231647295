import * as React from "react";
import styled, { keyframes } from "styled-components";
import { shadow, ThemeColor } from "ui/theme";
import { DelayedRemove } from "ui/delayed-remove";
import { BaseButton, RippleOrigin } from "ui/button";
const MenuContext = React.createContext({
    close() {
        console.error("Ussage of menu context outside of menu");
    }
});
const openKeyframes = keyframes `
from {
    opacity: 0;
    transform: scaleY(0.5);
    box-shadow: ${shadow(0)};
}
to {
    opacity: 1;
    transform: none;
    box-shadow: ${shadow(8)};
}
`;
const MenuContainer = styled.div `
will-change: opacity, transform, box-shadow;
transition: opacity 0.3s, transform 0.3s, box-shadow 0.3s;
transition-delay: 0.2s;
overflow: hidden;
background: ${ThemeColor.PaperLight};
border-radius: 2px;
opacity: ${({ open }) => open ? 1 : 0};
pointer-events: ${({ open }) => open ? "auto" : "none"};
transform: ${({ open }) => open ? "none" : "scaleY(0.5)"};
box-shadow: ${({ open }) => shadow(open ? 8 : 0)};
animation: 0.3s ${openKeyframes};
white-space: nowrap;
`;
export const Menu = React.memo(({ open, onHide, children, className, style }) => {
    const context = React.useMemo(() => ({
        close() {
            onHide();
        }
    }), [onHide]);
    const node = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const initTime = Date.now();
            function clickHandler(e) {
                if ((Date.now() - initTime) > 100
                    && !(node.current.contains(e.target) || node.current === e.target)) {
                    onHide();
                }
            }
            window.addEventListener("click", clickHandler);
            return () => window.removeEventListener("click", clickHandler);
        }
    }, [node, open, onHide]);
    return React.createElement(DelayedRemove, { visible: open, delay: 500 },
        React.createElement(MenuContext.Provider, { value: context },
            React.createElement(MenuContainer, { ref: node, open: open, style: style, className: className }, children)));
});
const MenuItemContainer = styled(BaseButton) `
display: flex;
flex-direction: row;
align-items: center;
transition: background-color 0.3s;
overflow: hidden;
padding: 11px 16px;
user-select: none;
position: relative;
font-size: 16px;
word-wrap: nowrap;
color: ${({ selected }) => selected ? ThemeColor.Accent : ThemeColor.PrimaryText};
:hover {
    background: ${ThemeColor.Border};
}
`;
export function MenuItem({ className, style, children, onClick, selected = false }) {
    const context = React.useContext(MenuContext), clickHandler = React.useCallback(() => {
        onClick();
        context.close();
    }, [context, onClick]);
    return React.createElement(MenuItemContainer, { selected: selected, rippleOrigin: RippleOrigin.MOUSE, rippleColor: ThemeColor.Border, className: className, style: style, onClick: clickHandler }, children);
}
