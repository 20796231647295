var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createReduxBlock } from "redux-sacala";
import { loadBackground, loadBackgroundList } from "api";
export const { actions: backgrounds, createMiddleware: backgroundsMiddleware, reducer: backgroundsReducer } = createReduxBlock()({
    name: "backgrounds",
    initial: {
        files: {},
        items: [],
        loading: [],
        failed: []
    },
    actions: {
        setItems: (_, items) => {
            return { items, loading: [], failed: [], files: {} };
        },
        startLoading: (state, id) => {
            return Object.assign(Object.assign({}, state), { loading: [...state.loading, id], failed: state.failed.filter(v => v !== id) });
        },
        failLoading: (state, id) => {
            const { loading, failed } = state;
            return Object.assign(Object.assign({}, state), { loading: loading.filter(v => v !== id), failed: [...failed, id] });
        },
        successLoading: (state, { id, file }) => {
            const { items, loading, failed } = state;
            return Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [id]: file }), loading: loading.filter(v => v !== id), failed: failed.filter(v => v !== id) });
        }
    },
    effects: (dispatch, getState) => {
        return {
            loadList() {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(backgrounds.setItems(yield loadBackgroundList()));
                });
            },
            load(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const { backgrounds: state } = getState(), { files, items, loading } = state;
                    if (!files[id] && loading.indexOf(id) === -1) {
                        try {
                            dispatch(backgrounds.startLoading(id));
                            dispatch(backgrounds.successLoading({ id, file: yield loadBackground(id) }));
                        }
                        catch (e) {
                            console.error(e);
                            dispatch(backgrounds.failLoading(id));
                        }
                    }
                });
            }
        };
    }
});
