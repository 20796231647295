import * as React from "react";
import { useState, useEffect } from "react";
export function DelayedRemove({ delay, visible, children }) {
    const [passed, setPassed] = useState(!visible);
    useEffect(() => {
        if (visible && passed) {
            setPassed(false);
        }
        else if (!visible && passed) {
            // element is hidden
        }
        else if (visible && !passed) {
            // element is visible
        }
        else if (!visible && !passed) {
            const timeout = setTimeout(() => {
                setPassed(true);
            }, delay);
            return () => clearTimeout(timeout);
        }
    }, [visible, passed, delay]);
    return (visible || !passed) ? React.createElement(React.Fragment, null, children) : null;
}
