var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
window.root = window;
import * as React from "react";
import { Provider } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { render } from "react-dom";
import { createZenStore } from "store";
import { music, selectCurrentTrack } from "state";
import { ThemeConnected, BackgroundConnected, FontsDialogConnected, DocumentScreenConnected, SwitchToLibraryButton, LibraryScreenConnected, SwitchToDocumentButton, EditorConnected, DownloadButtonConnected, SettingsConnected, FsButtonsConnected, FsEntriesConnected, FullscreenButtonConnected, CountersButtonConnected, SettingsButtonConnected, LoginButtonConnected, } from "connectors";
import { ScreenList } from "ui/screen";
import { serverTime } from "api";
import { Layout, RTButtons, RBButtons, LTButtons, Body } from "ui/layout";
require('file-loader?name=[name].[ext]!./index.html');
import * as selectors from "./state/selectors";
const reselectTools = require("reselect-tools");
reselectTools.registerSelectors(selectors);
const AppStyle = createGlobalStyle `
html, body, .root {
margin: 0;
padding: 0;
height: 100vh;
overflow: hidden;
}`;
const AppContainer = styled(ThemeConnected) `
width: 100%;
height: 100%;
`;
AppContainer.displayName = "AppContainer";
// Need it for the flip animations
const EntriesContainer = styled.div `position: relative;`;
(() => __awaiter(void 0, void 0, void 0, function* () {
    const store = yield createZenStore();
    reselectTools.getStateWith(() => store.getState());
    serverTime();
    document.head.appendChild(Object.assign(document.createElement("link"), {
        href: "https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap",
        rel: "stylesheet",
    }));
    const root = document.createElement("div");
    root.classList.add("root");
    document.body.appendChild(root);
    render(React.createElement(Provider, { store: store },
        React.createElement(AppStyle, null),
        React.createElement(AppContainer, null,
            React.createElement(BackgroundConnected, null),
            React.createElement(FontsDialogConnected, null),
            React.createElement(ScreenList, null,
                React.createElement(DocumentScreenConnected, null,
                    React.createElement(Layout, null,
                        React.createElement(LTButtons, null,
                            React.createElement(SwitchToLibraryButton, null)),
                        React.createElement(RBButtons, null,
                            React.createElement(CountersButtonConnected, null),
                            React.createElement(DownloadButtonConnected, null)),
                        React.createElement(RTButtons, null,
                            React.createElement(SettingsButtonConnected, null),
                            React.createElement(LoginButtonConnected, null),
                            React.createElement(FullscreenButtonConnected, null)),
                        React.createElement(Body, null,
                            React.createElement(EditorConnected, null)))),
                React.createElement(LibraryScreenConnected, null,
                    React.createElement(Layout, null,
                        React.createElement(LTButtons, null,
                            React.createElement(SwitchToDocumentButton, null)),
                        React.createElement(RTButtons, null,
                            React.createElement(SettingsButtonConnected, null),
                            React.createElement(LoginButtonConnected, null),
                            React.createElement(FullscreenButtonConnected, null)),
                        React.createElement(Body, null,
                            React.createElement(FsButtonsConnected, null),
                            React.createElement(EntriesContainer, null,
                                React.createElement(FsEntriesConnected, null))))),
                React.createElement(SettingsConnected, null)))), root);
    function startMusic() {
        console.log("Start music");
        store.dispatch(music.play(selectCurrentTrack(store.getState())));
        document.removeEventListener("click", startMusic);
        document.removeEventListener("keydown", startMusic);
    }
    document.addEventListener("click", startMusic);
    document.addEventListener("keydown", startMusic);
    setTimeout(() => window.hideLoadingOverlay(), 1000);
}))();
